import React, { useState } from 'react';
import { Search, Hash } from 'lucide-react';

interface MatchResult {
  exactMatch: string[];
  phraseMatch: string[];
  broadMatch: string[];
}

export default function KeywordMatchTool() {
  const [keyword, setKeyword] = useState('');
  const [matchResults, setMatchResults] = useState<MatchResult | null>(null);

  const analyzeKeyword = () => {
    const words = keyword.toLowerCase().trim().split(' ');
    
    // Generate variations
    const exactMatch = [`"${keyword.trim()}"`];
    const phraseMatch = [`"${keyword.trim()}"`];
    
    // Generate broad match variations
    const broadMatch = [
      keyword.trim(),
      ...words.map((_, i) => {
        const variation = [...words];
        if (i < words.length - 1) {
          [variation[i], variation[i + 1]] = [variation[i + 1], variation[i]];
        }
        return variation.join(' ');
      }),
      ...words.map(word => words.filter(w => w !== word).join(' ')), // Remove one word
    ];

    setMatchResults({
      exactMatch,
      phraseMatch,
      broadMatch: [...new Set(broadMatch)], // Remove duplicates
    });
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6 max-w-2xl mx-auto">
      <div className="flex items-center mb-6">
        <Hash className="h-8 w-8 text-brand-primary mr-3" />
        <h2 className="text-2xl font-bold text-gray-900">Keyword Match Tool</h2>
      </div>

      <div className="space-y-6">
        <div>
          <label htmlFor="keyword" className="block text-sm font-medium text-gray-700">
            Enter Your Keyword
          </label>
          <div className="mt-1 flex rounded-md shadow-sm">
            <input
              type="text"
              id="keyword"
              className="flex-1 block w-full rounded-md border-gray-300 focus:border-brand-primary focus:ring-brand-primary sm:text-sm"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              placeholder="Enter a keyword to analyze"
            />
            <button
              onClick={analyzeKeyword}
              className="ml-3 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-brand-primary hover:bg-brand-darker focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-primary"
            >
              <Search className="h-4 w-4 mr-2" />
              Analyze
            </button>
          </div>
        </div>

        {matchResults && (
          <div className="space-y-6">
            <div className="bg-gray-50 rounded-md p-4">
              <h3 className="text-lg font-medium text-gray-900 mb-3">Exact Match</h3>
              <ul className="space-y-2">
                {matchResults.exactMatch.map((match, index) => (
                  <li key={index} className="text-gray-600">
                    {match}
                  </li>
                ))}
              </ul>
            </div>

            <div className="bg-gray-50 rounded-md p-4">
              <h3 className="text-lg font-medium text-gray-900 mb-3">Phrase Match</h3>
              <ul className="space-y-2">
                {matchResults.phraseMatch.map((match, index) => (
                  <li key={index} className="text-gray-600">
                    {match}
                  </li>
                ))}
              </ul>
            </div>

            <div className="bg-gray-50 rounded-md p-4">
              <h3 className="text-lg font-medium text-gray-900 mb-3">Broad Match</h3>
              <ul className="space-y-2">
                {matchResults.broadMatch.map((match, index) => (
                  <li key={index} className="text-gray-600">
                    {match}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}