import React from 'react';
import { CalendarCheck } from 'lucide-react';

export default function CTABanner() {
  return (
    <div className="bg-gradient-to-r from-brand-primary to-brand-darker">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
            Want to Improve Your SEO Strategy?
          </h2>
          <p className="mt-4 text-xl text-white/90">
            Book a FREE strategy session with Julian Goldie and take your SEO to the next level.
          </p>
          <div className="mt-8">
            <a
              href="https://go.juliangoldie.com/strategy-session"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-brand-primary bg-white hover:bg-gray-50"
            >
              <CalendarCheck className="h-5 w-5 mr-2" />
              Book Your Free Session Now
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}