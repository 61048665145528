import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Search,
  ShoppingBag,
  Image,
  AlertTriangle,
  FileText,
  Group,
  Hash,
  Shuffle,
  DollarSign,
  Calculator,
  Building2,
  Building,
  Rocket,
  BarChart2,
  RefreshCw,
  ClipboardList,
  ListTodo,
  Menu,
  X,
  ChevronDown,
  Brain,
  ShoppingCart,
  Hotel
} from 'lucide-react';

export function Navigation() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [openGroup, setOpenGroup] = useState<string | null>(null);

  const navigationGroups = [
    {
      name: 'Keyword Research',
      items: [
        { name: 'KGR Calculator', href: '/kgr-calculator', icon: Search },
        { name: 'Affiliate Keywords', href: '/affiliate-keywords', icon: ShoppingBag },
        { name: 'Etsy Keywords', href: '/etsy-keywords', icon: ShoppingBag },
        { name: 'Pinterest Keywords', href: '/pinterest-keywords', icon: Image },
      ],
    },
    {
      name: 'Content Analysis',
      items: [
        { name: 'Semantic SEO', href: '/semantic-seo', icon: Brain },
        { name: 'Semantic Content Analysis', href: '/semantic-content-analysis', icon: FileText },
        { name: 'Cannibalization Tool', href: '/keyword-cannibalization', icon: AlertTriangle },
        { name: 'Keyword Extraction', href: '/keyword-extraction', icon: FileText },
      ],
    },
    {
      name: 'Keyword Tools',
      items: [
        { name: 'Clustering Tool', href: '/keyword-clustering', icon: Group },
        { name: 'Match Tool', href: '/keyword-match', icon: Hash },
        { name: 'Broad Match Tool', href: '/broad-match', icon: Shuffle },
        { name: 'CPC Calculator', href: '/cpc-calculator', icon: DollarSign },
        { name: 'Concatenation Tool', href: '/keyword-concatenation', icon: Calculator },
      ],
    },
    {
      name: 'Business Tools',
      items: [
        { name: 'Enterprise SEO', href: '/enterprise-seo', icon: Building2 },
        { name: 'Small Business SEO', href: '/small-business-seo', icon: Building },
        { name: 'SaaS SEO', href: '/saas-seo', icon: Rocket },
        { name: 'Amazon SEO', href: '/amazon-seo', icon: ShoppingCart },
        { name: 'Hotel SEO', href: '/hotel-seo', icon: Hotel },
        { name: 'Ecommerce SEO', href: '/ecommerce-seo', icon: ShoppingBag },
        { name: 'SEO Reporting', href: '/seo-reporting', icon: BarChart2 },
        { name: 'Automated Reporting', href: '/automated-seo-reporting', icon: RefreshCw },
        { name: 'Project Manager', href: '/seo-project-manager', icon: ClipboardList },
        { name: 'Task Management', href: '/seo-task-management', icon: ListTodo },
      ],
    },
  ];

  const toggleGroup = (groupName: string) => {
    setOpenGroup(openGroup === groupName ? null : groupName);
  };

  return (
    <nav className="bg-white shadow-lg">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex">
            <div className="flex-shrink-0 flex items-center">
              <Link to="/" className="text-xl font-bold text-brand-primary">
                SEO Tools
              </Link>
            </div>
          </div>

          {/* Desktop Navigation */}
          <div className="hidden md:flex md:items-center md:space-x-4">
            {navigationGroups.map((group) => (
              <div key={group.name} className="relative group">
                <button
                  className="inline-flex items-center px-3 py-2 text-sm font-medium text-gray-700 hover:text-brand-primary"
                  onClick={() => toggleGroup(group.name)}
                >
                  {group.name}
                  <ChevronDown className="ml-1 h-4 w-4" />
                </button>
                <div className="absolute z-10 left-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200">
                  <div className="py-1">
                    {group.items.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      >
                        <item.icon className="mr-3 h-4 w-4" />
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Mobile menu button */}
          <div className="md:hidden flex items-center">
            <button
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-700 hover:text-brand-primary focus:outline-none"
            >
              {mobileMenuOpen ? (
                <X className="h-6 w-6" />
              ) : (
                <Menu className="h-6 w-6" />
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      {mobileMenuOpen && (
        <div className="md:hidden">
          <div className="pt-2 pb-3 space-y-1">
            {navigationGroups.map((group) => (
              <div key={group.name} className="px-4">
                <button
                  className="w-full flex items-center justify-between py-2 text-base font-medium text-gray-700"
                  onClick={() => toggleGroup(group.name)}
                >
                  {group.name}
                  <ChevronDown className={`ml-1 h-4 w-4 transform transition-transform duration-200 ${
                    openGroup === group.name ? 'rotate-180' : ''
                  }`} />
                </button>
                {openGroup === group.name && (
                  <div className="ml-4 space-y-1">
                    {group.items.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className="flex items-center py-2 pl-3 pr-4 text-base font-medium text-gray-600 hover:text-brand-primary"
                        onClick={() => setMobileMenuOpen(false)}
                      >
                        <item.icon className="mr-3 h-4 w-4" />
                        {item.name}
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </nav>
  );
}