import React from 'react';
import KeywordCannibalizationTool from '../components/KeywordCannibalizationTool';
import CTABanner from '../components/CTABanner';

export function KeywordCannibalizationPage() {
  return (
    <div className="py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h1 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Keyword Cannibalization Tool
          </h1>
          <p className="mt-4 text-xl text-gray-500 max-w-3xl mx-auto">
            Identify and fix keyword cannibalization issues to improve your SEO rankings.
          </p>
        </div>
        
        <KeywordCannibalizationTool />
        
        <div className="prose prose-lg max-w-3xl mx-auto mt-16">
          <h2>Understanding Keyword Cannibalization</h2>
          <p>
            Keyword cannibalization occurs when multiple pages on your website compete for the same search terms in Google. This internal competition can dilute your SEO efforts and prevent your pages from reaching their full ranking potential.
          </p>

          <h2>How to Use the Keyword Cannibalization Tool</h2>
          <p>
            Our tool helps you identify and analyze potential keyword cannibalization issues across your website. Follow these steps to conduct a thorough analysis:
          </p>

          <ol>
            <li>Enter your target keyword in the search field</li>
            <li>Add the URLs of pages targeting this keyword</li>
            <li>Input the current Google ranking position for each URL</li>
            <li>Click "Analyze Cannibalization" to get results</li>
          </ol>

          <h2>How to Fix Keyword Cannibalization</h2>
          <ul>
            <li>Consolidate similar content into a single, comprehensive page</li>
            <li>Implement canonical tags to indicate your preferred URL</li>
            <li>Modify content to target different keyword variations</li>
            <li>Create a hierarchy between related pages</li>
            <li>Remove or redirect redundant content</li>
          </ul>
        </div>
      </div>
      <div className="mt-16">
        <CTABanner />
      </div>
    </div>
  );
}