import React, { useState } from 'react';
import { ClipboardList, Plus, Calendar, Users, CheckSquare, Trash2, Tag } from 'lucide-react';

interface SEOProject {
  id: string;
  name: string;
  status: 'planning' | 'in-progress' | 'review' | 'completed';
  priority: 'high' | 'medium' | 'low';
  dueDate: string;
  owner: string;
  tasks: Array<{
    id: string;
    title: string;
    status: 'todo' | 'in-progress' | 'completed';
    type: 'technical' | 'content' | 'onpage' | 'offpage';
  }>;
}

export default function SEOProjectManagerTool() {
  const [projects, setProjects] = useState<SEOProject[]>([]);
  const [newProject, setNewProject] = useState({
    name: '',
    priority: 'medium' as const,
    dueDate: '',
    owner: '',
  });

  const addProject = () => {
    if (!newProject.name) return;

    setProjects([
      ...projects,
      {
        id: Date.now().toString(),
        ...newProject,
        status: 'planning' as const,
        tasks: [],
      },
    ]);

    setNewProject({
      name: '',
      priority: 'medium',
      dueDate: '',
      owner: '',
    });
  };

  const updateProjectStatus = (projectId: string, status: SEOProject['status']) => {
    setProjects(projects.map(project => 
      project.id === projectId ? { ...project, status } : project
    ));
  };

  const deleteProject = (projectId: string) => {
    setProjects(projects.filter(project => project.id !== projectId));
  };

  const getPriorityColor = (priority: string) => {
    switch (priority) {
      case 'high': return 'text-red-500';
      case 'medium': return 'text-yellow-500';
      case 'low': return 'text-green-500';
      default: return 'text-gray-500';
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6 max-w-4xl mx-auto">
      <div className="flex items-center mb-6">
        <ClipboardList className="h-8 w-8 text-brand-primary mr-3" />
        <h2 className="text-2xl font-bold text-gray-900">SEO Project Manager Tool</h2>
      </div>

      <div className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <input
            type="text"
            placeholder="Project name"
            className="rounded-md border-gray-300 shadow-sm focus:border-brand-primary focus:ring-brand-primary"
            value={newProject.name}
            onChange={(e) => setNewProject({ ...newProject, name: e.target.value })}
          />
          <select
            className="rounded-md border-gray-300 shadow-sm focus:border-brand-primary focus:ring-brand-primary"
            value={newProject.priority}
            onChange={(e) => setNewProject({ ...newProject, priority: e.target.value as any })}
          >
            <option value="high">High Priority</option>
            <option value="medium">Medium Priority</option>
            <option value="low">Low Priority</option>
          </select>
          <input
            type="date"
            className="rounded-md border-gray-300 shadow-sm focus:border-brand-primary focus:ring-brand-primary"
            value={newProject.dueDate}
            onChange={(e) => setNewProject({ ...newProject, dueDate: e.target.value })}
          />
          <input
            type="text"
            placeholder="Project owner"
            className="rounded-md border-gray-300 shadow-sm focus:border-brand-primary focus:ring-brand-primary"
            value={newProject.owner}
            onChange={(e) => setNewProject({ ...newProject, owner: e.target.value })}
          />
        </div>

        <button
          onClick={addProject}
          className="btn-primary w-full flex items-center justify-center"
        >
          <Plus className="h-5 w-5 mr-2" />
          Add SEO Project
        </button>

        <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
          {/* Planning Column */}
          <div className="bg-gray-50 rounded-lg p-4">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Planning</h3>
            <div className="space-y-3">
              {projects.filter(project => project.status === 'planning').map(project => (
                <div key={project.id} className="bg-white p-4 rounded-md shadow-sm">
                  <div className="flex items-center justify-between">
                    <h4 className="font-medium text-gray-900">{project.name}</h4>
                    <span className={getPriorityColor(project.priority)}>
                      {project.priority.charAt(0).toUpperCase() + project.priority.slice(1)}
                    </span>
                  </div>
                  <div className="mt-2 text-sm text-gray-500">
                    <p>Owner: {project.owner}</p>
                    <p>Due: {project.dueDate}</p>
                  </div>
                  <div className="mt-3 flex justify-between">
                    <button
                      onClick={() => updateProjectStatus(project.id, 'in-progress')}
                      className="text-brand-primary hover:text-brand-darker"
                    >
                      Start
                    </button>
                    <button
                      onClick={() => deleteProject(project.id)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <Trash2 className="h-4 w-4" />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* In Progress Column */}
          <div className="bg-gray-50 rounded-lg p-4">
            <h3 className="text-lg font-medium text-gray-900 mb-4">In Progress</h3>
            <div className="space-y-3">
              {projects.filter(project => project.status === 'in-progress').map(project => (
                <div key={project.id} className="bg-white p-4 rounded-md shadow-sm">
                  <div className="flex items-center justify-between">
                    <h4 className="font-medium text-gray-900">{project.name}</h4>
                    <span className={getPriorityColor(project.priority)}>
                      {project.priority.charAt(0).toUpperCase() + project.priority.slice(1)}
                    </span>
                  </div>
                  <div className="mt-2 text-sm text-gray-500">
                    <p>Owner: {project.owner}</p>
                    <p>Due: {project.dueDate}</p>
                  </div>
                  <div className="mt-3 flex justify-between">
                    <button
                      onClick={() => updateProjectStatus(project.id, 'review')}
                      className="text-brand-primary hover:text-brand-darker"
                    >
                      Review
                    </button>
                    <button
                      onClick={() => deleteProject(project.id)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <Trash2 className="h-4 w-4" />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Review Column */}
          <div className="bg-gray-50 rounded-lg p-4">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Review</h3>
            <div className="space-y-3">
              {projects.filter(project => project.status === 'review').map(project => (
                <div key={project.id} className="bg-white p-4 rounded-md shadow-sm">
                  <div className="flex items-center justify-between">
                    <h4 className="font-medium text-gray-900">{project.name}</h4>
                    <span className={getPriorityColor(project.priority)}>
                      {project.priority.charAt(0).toUpperCase() + project.priority.slice(1)}
                    </span>
                  </div>
                  <div className="mt-2 text-sm text-gray-500">
                    <p>Owner: {project.owner}</p>
                    <p>Due: {project.dueDate}</p>
                  </div>
                  <div className="mt-3 flex justify-between">
                    <button
                      onClick={() => updateProjectStatus(project.id, 'completed')}
                      className="text-green-500 hover:text-green-700"
                    >
                      Complete
                    </button>
                    <button
                      onClick={() => deleteProject(project.id)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <Trash2 className="h-4 w-4" />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Completed Column */}
          <div className="bg-gray-50 rounded-lg p-4">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Completed</h3>
            <div className="space-y-3">
              {projects.filter(project => project.status === 'completed').map(project => (
                <div key={project.id} className="bg-white p-4 rounded-md shadow-sm">
                  <div className="flex items-center justify-between">
                    <h4 className="font-medium text-gray-900">{project.name}</h4>
                    <span className={getPriorityColor(project.priority)}>
                      {project.priority.charAt(0).toUpperCase() + project.priority.slice(1)}
                    </span>
                  </div>
                  <div className="mt-2 text-sm text-gray-500">
                    <p>Owner: {project.owner}</p>
                    <p>Due: {project.dueDate}</p>
                  </div>
                  <div className="mt-3 flex justify-end">
                    <button
                      onClick={() => deleteProject(project.id)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <Trash2 className="h-4 w-4" />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}