import React from 'react';
import HotelSEOTool from '../components/HotelSEOTool';
import CTABanner from '../components/CTABanner';

export function HotelSEOPage() {
  return (
    <div className="py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h1 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Hotel SEO Tool
          </h1>
          <p className="mt-4 text-xl text-gray-500 max-w-3xl mx-auto">
            Optimize your hotel's online presence and boost direct bookings with our specialized hotel SEO tool.
          </p>
        </div>
        
        <HotelSEOTool />
        
        <div className="prose prose-lg max-w-3xl mx-auto mt-16">
          <h2>How to Use Our Hotel SEO Tool</h2>
          <p>
            Our specialized hotel SEO tool helps accommodation providers improve their online visibility and drive more direct bookings. Whether you're managing a boutique hotel or a large resort, this comprehensive guide will show you how to leverage our tool for maximum impact.
          </p>

          <h3>Understanding Hotel SEO Metrics</h3>
          <p>
            The tool provides key metrics specific to the hospitality industry:
          </p>
          
          <ul>
            <li><strong>Local Visibility:</strong> Your hotel's visibility in local search results</li>
            <li><strong>Booking Intent:</strong> Likelihood of searchers making a reservation</li>
            <li><strong>Competitor Rank:</strong> Your position compared to local competitors</li>
            <li><strong>Local Pack Ranking:</strong> Position in Google's Local Pack results</li>
          </ul>

          <h3>Optimizing Your Hotel's Online Presence</h3>
          <p>
            Follow these steps to improve your hotel's search visibility:
          </p>

          <ol>
            <li>Enter your hotel's website domain</li>
            <li>Specify your exact location</li>
            <li>Analyze local SEO metrics</li>
            <li>Review optimization opportunities</li>
            <li>Implement recommended changes</li>
          </ol>

          <h3>Local SEO for Hotels</h3>
          <p>
            Focus on these key areas for local search success:
          </p>

          <ul>
            <li>Google Business Profile optimization</li>
            <li>Local keyword targeting</li>
            <li>Location-based content creation</li>
            <li>Review management</li>
            <li>Local citation building</li>
          </ul>

          <h3>Content Optimization Strategies</h3>
          <p>
            Enhance your hotel's content with these approaches:
          </p>

          <ul>
            <li>Create location-specific landing pages</li>
            <li>Highlight nearby attractions</li>
            <li>Showcase amenities and services</li>
            <li>Implement proper schema markup</li>
            <li>Optimize for local events</li>
          </ul>

          <h3>Technical SEO for Hotels</h3>
          <p>
            Ensure your technical foundation is solid:
          </p>

          <ul>
            <li>Mobile optimization</li>
            <li>Fast loading speeds</li>
            <li>Proper schema markup</li>
            <li>Secure booking system</li>
            <li>Optimized images and media</li>
          </ul>
        </div>
      </div>
      <div className="mt-16">
        <CTABanner />
      </div>
    </div>
  );
}