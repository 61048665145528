import React, { useState } from 'react';
import { Building2, Search, BarChart3, Download } from 'lucide-react';

interface SEOMetric {
  domain: string;
  metrics: {
    authority: number;
    traffic: number;
    keywords: number;
    compliance: number;
    performance: number;
  };
  issues: string[];
}

export default function EnterpriseSEOTool() {
  const [domain, setDomain] = useState('');
  const [results, setResults] = useState<SEOMetric | null>(null);

  const analyzeDomain = () => {
    // Simulated enterprise SEO analysis
    setResults({
      domain: domain,
      metrics: {
        authority: Math.floor(Math.random() * 30) + 70, // 70-100 range
        traffic: Math.floor(Math.random() * 500000) + 500000,
        keywords: Math.floor(Math.random() * 50000) + 50000,
        compliance: Math.floor(Math.random() * 20) + 80, // 80-100 range
        performance: Math.floor(Math.random() * 15) + 85, // 85-100 range
      },
      issues: [
        'Mobile optimization needed on /products',
        'Duplicate meta descriptions on blog posts',
        'Slow loading time on resource pages',
        'Missing alt tags on product images',
      ],
    });
  };

  const downloadReport = () => {
    if (!results) return;
    
    const reportData = `
Enterprise SEO Analysis Report
Domain: ${results.domain}

Metrics:
- Domain Authority: ${results.metrics.authority}
- Monthly Traffic: ${results.metrics.traffic.toLocaleString()}
- Ranking Keywords: ${results.metrics.keywords.toLocaleString()}
- Compliance Score: ${results.metrics.compliance}%
- Performance Score: ${results.metrics.performance}%

Issues Identified:
${results.issues.map(issue => `- ${issue}`).join('\n')}
    `.trim();

    const blob = new Blob([reportData], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${domain}-enterprise-seo-report.txt`;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6 max-w-4xl mx-auto">
      <div className="flex items-center mb-6">
        <Building2 className="h-8 w-8 text-brand-primary mr-3" />
        <h2 className="text-2xl font-bold text-gray-900">Enterprise Search Engine Optimization Tool</h2>
      </div>

      <div className="space-y-6">
        <div>
          <label htmlFor="domain" className="block text-sm font-medium text-gray-700">
            Enterprise Domain
          </label>
          <div className="mt-1 flex rounded-md shadow-sm">
            <input
              type="text"
              id="domain"
              className="flex-1 block w-full rounded-md border-gray-300 focus:border-brand-primary focus:ring-brand-primary sm:text-sm"
              value={domain}
              onChange={(e) => setDomain(e.target.value)}
              placeholder="Enter your enterprise domain (e.g., enterprise.com)"
            />
            <button
              onClick={analyzeDomain}
              className="ml-3 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-brand-primary hover:bg-brand-darker focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-primary"
            >
              <Search className="h-4 w-4 mr-2" />
              Analyze
            </button>
          </div>
        </div>

        {results && (
          <div className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="bg-gray-50 rounded-lg p-4">
                <div className="flex items-center justify-between">
                  <h3 className="text-sm font-medium text-gray-500">Domain Authority</h3>
                  <span className="text-2xl font-bold text-gray-900">{results.metrics.authority}</span>
                </div>
              </div>
              <div className="bg-gray-50 rounded-lg p-4">
                <div className="flex items-center justify-between">
                  <h3 className="text-sm font-medium text-gray-500">Monthly Traffic</h3>
                  <span className="text-2xl font-bold text-gray-900">
                    {results.metrics.traffic.toLocaleString()}
                  </span>
                </div>
              </div>
              <div className="bg-gray-50 rounded-lg p-4">
                <div className="flex items-center justify-between">
                  <h3 className="text-sm font-medium text-gray-500">Ranking Keywords</h3>
                  <span className="text-2xl font-bold text-gray-900">
                    {results.metrics.keywords.toLocaleString()}
                  </span>
                </div>
              </div>
            </div>

            <div className="bg-gray-50 rounded-lg p-6">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Critical Issues</h3>
              <ul className="space-y-2">
                {results.issues.map((issue, index) => (
                  <li key={index} className="flex items-start">
                    <span className="flex-shrink-0 h-5 w-5 text-red-500">⚠️</span>
                    <span className="ml-2 text-gray-700">{issue}</span>
                  </li>
                ))}
              </ul>
            </div>

            <button
              onClick={downloadReport}
              className="btn-secondary w-full flex items-center justify-center"
            >
              <Download className="h-5 w-5 mr-2" />
              Download Full Report
            </button>
          </div>
        )}
      </div>
    </div>
  );
}