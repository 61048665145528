import React from 'react';
import SEOTaskManagementTool from '../components/SEOTaskManagementTool';
import CTABanner from '../components/CTABanner';

export function SEOTaskManagementPage() {
  return (
    <div className="py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h1 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            SEO Task Management Tool
          </h1>
          <p className="mt-4 text-xl text-gray-500 max-w-3xl mx-auto">
            Streamline your SEO workflow with our comprehensive task management tool designed for SEO professionals.
          </p>
        </div>
        
        <SEOTaskManagementTool />
        
        <div className="prose prose-lg max-w-3xl mx-auto mt-16">
          <h2>How to Use the SEO Task Management Tool</h2>
          <p>
            Our SEO task management tool helps you organize, track, and optimize your SEO workflows effectively. Whether you're managing multiple projects or coordinating with team members, this tool streamlines your SEO task management process.
          </p>

          <h3>Getting Started with SEO Task Management</h3>
          <p>
            The tool provides a comprehensive task management solution specifically designed for SEO tasks:
          </p>
          
          <ul>
            <li>Create and assign SEO-specific tasks</li>
            <li>Categorize tasks by SEO type (Technical, Content, On-Page, Off-Page)</li>
            <li>Set priorities and deadlines</li>
            <li>Track progress through different stages</li>
            <li>Monitor team performance</li>
          </ul>

          <h3>Creating and Managing SEO Tasks</h3>
          <p>
            Follow these steps to manage your SEO tasks efficiently:
          </p>

          <ol>
            <li>Enter task details including title and description</li>
            <li>Select the appropriate SEO category</li>
            <li>Assign team members and set deadlines</li>
            <li>Prioritize tasks based on importance</li>
            <li>Track progress through the workflow stages</li>
          </ol>

          <h3>Task Categories and Organization</h3>
          <p>
            The tool organizes tasks into four main SEO categories:
          </p>

          <ul>
            <li><strong>Technical SEO:</strong> Site structure, speed, and technical optimizations</li>
            <li><strong>Content SEO:</strong> Content creation, optimization, and strategy</li>
            <li><strong>On-Page SEO:</strong> Meta tags, internal linking, and page optimizations</li>
            <li><strong>Off-Page SEO:</strong> Link building, outreach, and external optimization</li>
          </ul>

          <h3>Workflow Management</h3>
          <p>
            Tasks move through three main stages:
          </p>

          <ul>
            <li><strong>To Do:</strong> New tasks awaiting action</li>
            <li><strong>In Progress:</strong> Tasks currently being worked on</li>
            <li><strong>Completed:</strong> Successfully finished tasks</li>
          </ul>

          <h3>Best Practices for SEO Task Management</h3>
          <p>
            Follow these guidelines for optimal results:
          </p>

          <ul>
            <li>Break down large SEO projects into manageable tasks</li>
            <li>Set realistic deadlines for each task</li>
            <li>Assign clear responsibilities to team members</li>
            <li>Use task descriptions to provide detailed instructions</li>
            <li>Regularly update task status and progress</li>
          </ul>
        </div>
      </div>
      <div className="mt-16">
        <CTABanner />
      </div>
    </div>
  );
}