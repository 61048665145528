import React, { useState } from 'react';
import { FileText, Download, Search } from 'lucide-react';

interface ExtractedKeyword {
  keyword: string;
  frequency: number;
  density: number;
  importance: number;
}

export default function KeywordExtractionTool() {
  const [content, setContent] = useState('');
  const [keywords, setKeywords] = useState<ExtractedKeyword[]>([]);
  const [minFrequency, setMinFrequency] = useState(2);

  const extractKeywords = () => {
    // Basic keyword extraction logic
    const words = content.toLowerCase()
      .replace(/[^\w\s]/g, '')
      .split(/\s+/)
      .filter(word => word.length > 2);

    const totalWords = words.length;
    const frequencies: { [key: string]: number } = {};
    
    words.forEach(word => {
      frequencies[word] = (frequencies[word] || 0) + 1;
    });

    const extractedKeywords: ExtractedKeyword[] = Object.entries(frequencies)
      .filter(([_, freq]) => freq >= minFrequency)
      .map(([word, frequency]) => ({
        keyword: word,
        frequency,
        density: (frequency / totalWords) * 100,
        importance: calculateImportance(word, frequency, totalWords),
      }))
      .sort((a, b) => b.importance - a.importance)
      .slice(0, 20);

    setKeywords(extractedKeywords);
  };

  const calculateImportance = (word: string, frequency: number, totalWords: number) => {
    const density = (frequency / totalWords) * 100;
    const wordLength = word.length;
    return density * (1 + (wordLength / 20)); // Simple importance score
  };

  const downloadCSV = () => {
    const csvContent = [
      ['Keyword', 'Frequency', 'Density (%)', 'Importance Score'],
      ...keywords.map(k => [
        k.keyword,
        k.frequency,
        k.density.toFixed(2),
        k.importance.toFixed(2)
      ])
    ].map(row => row.join(',')).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'extracted-keywords.csv';
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6 max-w-4xl mx-auto">
      <div className="flex items-center mb-6">
        <FileText className="h-8 w-8 text-brand-primary mr-3" />
        <h2 className="text-2xl font-bold text-gray-900">Free Keyword Extraction Tool</h2>
      </div>

      <div className="space-y-6">
        <div>
          <label htmlFor="content" className="block text-sm font-medium text-gray-700">
            Paste Your Content
          </label>
          <textarea
            id="content"
            rows={8}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-primary focus:ring-brand-primary sm:text-sm"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            placeholder="Paste your content here to extract keywords..."
          />
        </div>

        <div>
          <label htmlFor="minFrequency" className="block text-sm font-medium text-gray-700">
            Minimum Keyword Frequency
          </label>
          <input
            type="number"
            id="minFrequency"
            min="1"
            max="10"
            className="mt-1 block w-32 rounded-md border-gray-300 shadow-sm focus:border-brand-primary focus:ring-brand-primary sm:text-sm"
            value={minFrequency}
            onChange={(e) => setMinFrequency(parseInt(e.target.value))}
          />
        </div>

        <div className="flex gap-4">
          <button
            onClick={extractKeywords}
            className="btn-primary flex-grow flex items-center justify-center"
          >
            <Search className="h-5 w-5 mr-2" />
            Extract Keywords
          </button>
          {keywords.length > 0 && (
            <button
              onClick={downloadCSV}
              className="btn-secondary flex items-center"
            >
              <Download className="h-5 w-5 mr-2" />
              Export CSV
            </button>
          )}
        </div>

        {keywords.length > 0 && (
          <div className="mt-8">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Extracted Keywords</h3>
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Keyword
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Frequency
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Density
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Importance
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {keywords.map((keyword, index) => (
                    <tr key={index}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {keyword.keyword}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {keyword.frequency}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {keyword.density.toFixed(2)}%
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {keyword.importance.toFixed(2)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}