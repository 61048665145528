import React, { useState } from 'react';
import { ShoppingBag, Search, BarChart2, Download } from 'lucide-react';

interface EcommerceAnalysis {
  domain: string;
  metrics: {
    organicTraffic: number;
    conversionRate: number;
    revenueImpact: number;
    productVisibility: number;
  };
  opportunities: Array<{
    type: string;
    impact: 'high' | 'medium' | 'low';
    description: string;
  }>;
  productKeywords: Array<{
    keyword: string;
    volume: number;
    competition: number;
    buyingIntent: number;
  }>;
}

export default function EcommerceSEOTool() {
  const [domain, setDomain] = useState('');
  const [platform, setPlatform] = useState('all');
  const [analysis, setAnalysis] = useState<EcommerceAnalysis | null>(null);

  const analyzeStore = () => {
    // Simulated ecommerce SEO analysis
    setAnalysis({
      domain,
      metrics: {
        organicTraffic: Math.floor(Math.random() * 50000) + 10000,
        conversionRate: (Math.random() * 3 + 1).toFixed(2),
        revenueImpact: Math.floor(Math.random() * 100000) + 20000,
        productVisibility: Math.floor(Math.random() * 30) + 70,
      },
      opportunities: [
        {
          type: 'Product Pages',
          impact: 'high',
          description: 'Optimize product descriptions with long-tail keywords',
        },
        {
          type: 'Category Pages',
          impact: 'high',
          description: 'Improve category page structure and internal linking',
        },
        {
          type: 'Technical SEO',
          impact: 'medium',
          description: 'Implement schema markup for products and reviews',
        },
      ],
      productKeywords: [
        {
          keyword: 'best selling products',
          volume: Math.floor(Math.random() * 5000) + 1000,
          competition: Math.random() * 0.5 + 0.3,
          buyingIntent: Math.random() * 0.3 + 0.7,
        },
        {
          keyword: 'product reviews',
          volume: Math.floor(Math.random() * 3000) + 500,
          competition: Math.random() * 0.4 + 0.2,
          buyingIntent: Math.random() * 0.2 + 0.7,
        },
        {
          keyword: 'product comparison',
          volume: Math.floor(Math.random() * 2000) + 300,
          competition: Math.random() * 0.3 + 0.2,
          buyingIntent: Math.random() * 0.2 + 0.6,
        },
      ],
    });
  };

  const downloadReport = () => {
    if (!analysis) return;

    const reportContent = `
Best SEO Tool for Ecommerce Analysis Report
Domain: ${analysis.domain}
Platform: ${platform}

Key Metrics:
- Monthly Organic Traffic: ${analysis.metrics.organicTraffic.toLocaleString()}
- Conversion Rate: ${analysis.metrics.conversionRate}%
- Revenue Impact: $${analysis.metrics.revenueImpact.toLocaleString()}
- Product Visibility: ${analysis.metrics.productVisibility}%

Opportunities:
${analysis.opportunities.map(opp => `
${opp.type} (${opp.impact} impact)
- ${opp.description}
`).join('\n')}

Product Keywords:
${analysis.productKeywords.map(k => `
${k.keyword}
- Monthly Volume: ${k.volume.toLocaleString()}
- Competition: ${(k.competition * 100).toFixed()}%
- Buying Intent: ${(k.buyingIntent * 100).toFixed()}%
`).join('\n')}
    `.trim();

    const blob = new Blob([reportContent], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${domain}-ecommerce-seo-report.txt`;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6 max-w-4xl mx-auto">
      <div className="flex items-center mb-6">
        <ShoppingBag className="h-8 w-8 text-brand-primary mr-3" />
        <h2 className="text-2xl font-bold text-gray-900">Best SEO Tool for Ecommerce</h2>
      </div>

      <div className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label htmlFor="domain" className="block text-sm font-medium text-gray-700">
              Store Domain
            </label>
            <input
              type="text"
              id="domain"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-primary focus:ring-brand-primary sm:text-sm"
              value={domain}
              onChange={(e) => setDomain(e.target.value)}
              placeholder="Enter your store domain"
            />
          </div>
          <div>
            <label htmlFor="platform" className="block text-sm font-medium text-gray-700">
              Ecommerce Platform
            </label>
            <select
              id="platform"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-primary focus:ring-brand-primary sm:text-sm"
              value={platform}
              onChange={(e) => setPlatform(e.target.value)}
            >
              <option value="all">All Platforms</option>
              <option value="shopify">Shopify</option>
              <option value="woocommerce">WooCommerce</option>
              <option value="magento">Magento</option>
              <option value="bigcommerce">BigCommerce</option>
            </select>
          </div>
        </div>

        <div className="flex gap-4">
          <button
            onClick={analyzeStore}
            className="btn-primary flex-grow flex items-center justify-center"
          >
            <Search className="h-5 w-5 mr-2" />
            Analyze Store
          </button>
          {analysis && (
            <button
              onClick={downloadReport}
              className="btn-secondary flex items-center"
            >
              <Download className="h-5 w-5 mr-2" />
              Export Report
            </button>
          )}
        </div>

        {analysis && (
          <div className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              <div className="bg-gray-50 rounded-lg p-4">
                <h3 className="text-sm font-medium text-gray-500">Organic Traffic</h3>
                <p className="mt-2 text-3xl font-bold text-gray-900">
                  {analysis.metrics.organicTraffic.toLocaleString()}
                </p>
              </div>
              <div className="bg-gray-50 rounded-lg p-4">
                <h3 className="text-sm font-medium text-gray-500">Conversion Rate</h3>
                <p className="mt-2 text-3xl font-bold text-gray-900">
                  {analysis.metrics.conversionRate}%
                </p>
              </div>
              <div className="bg-gray-50 rounded-lg p-4">
                <h3 className="text-sm font-medium text-gray-500">Revenue Impact</h3>
                <p className="mt-2 text-3xl font-bold text-gray-900">
                  ${analysis.metrics.revenueImpact.toLocaleString()}
                </p>
              </div>
              <div className="bg-gray-50 rounded-lg p-4">
                <h3 className="text-sm font-medium text-gray-500">Product Visibility</h3>
                <p className="mt-2 text-3xl font-bold text-gray-900">
                  {analysis.metrics.productVisibility}%
                </p>
              </div>
            </div>

            <div className="bg-gray-50 rounded-lg p-6">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Growth Opportunities</h3>
              <div className="space-y-4">
                {analysis.opportunities.map((opp, index) => (
                  <div key={index} className="bg-white p-4 rounded-md shadow-sm">
                    <div className="flex items-center justify-between">
                      <h4 className="font-medium text-gray-900">{opp.type}</h4>
                      <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                        opp.impact === 'high' ? 'bg-red-100 text-red-800' :
                        opp.impact === 'medium' ? 'bg-yellow-100 text-yellow-800' :
                        'bg-green-100 text-green-800'
                      }`}>
                        {opp.impact.toUpperCase()} Impact
                      </span>
                    </div>
                    <p className="mt-2 text-sm text-gray-600">{opp.description}</p>
                  </div>
                ))}
              </div>
            </div>

            <div className="bg-gray-50 rounded-lg p-6">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Product Keywords</h3>
              <div className="space-y-4">
                {analysis.productKeywords.map((keyword, index) => (
                  <div key={index} className="bg-white p-4 rounded-md shadow-sm">
                    <div className="flex items-center justify-between">
                      <h4 className="font-medium text-gray-900">{keyword.keyword}</h4>
                      <span className="text-sm text-gray-500">
                        {keyword.volume.toLocaleString()} searches/mo
                      </span>
                    </div>
                    <div className="mt-2 grid grid-cols-2 gap-4 text-sm text-gray-600">
                      <div>
                        Competition: {(keyword.competition * 100).toFixed()}%
                      </div>
                      <div>
                        Buying Intent: {(keyword.buyingIntent * 100).toFixed()}%
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}