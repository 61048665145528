import React, { useState } from 'react';
import { Calculator } from 'lucide-react';

export default function KGRCalculator() {
  const [allInTitle, setAllInTitle] = useState('');
  const [searchVolume, setSearchVolume] = useState('');
  const [kgrResult, setKgrResult] = useState<number | null>(null);
  const [kgrInterpretation, setKgrInterpretation] = useState('');

  const calculateKGR = () => {
    const allInTitleNum = parseInt(allInTitle);
    const searchVolumeNum = parseInt(searchVolume);

    if (isNaN(allInTitleNum) || isNaN(searchVolumeNum) || searchVolumeNum === 0) {
      return;
    }

    const kgr = allInTitleNum / searchVolumeNum;
    setKgrResult(kgr);

    if (kgr < 0.25) {
      setKgrInterpretation('Great opportunity! This keyword has low competition.');
    } else if (kgr >= 0.25 && kgr <= 1) {
      setKgrInterpretation('Moderate opportunity. Consider targeting this keyword.');
    } else {
      setKgrInterpretation('High competition. Consider finding alternative keywords.');
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6 max-w-2xl mx-auto">
      <div className="flex items-center mb-6">
        <Calculator className="h-8 w-8 text-brand-primary mr-3" />
        <h2 className="text-2xl font-bold text-gray-900">Keyword Golden Ratio Calculator</h2>
      </div>

      <div className="space-y-6">
        <div>
          <label htmlFor="allInTitle" className="block text-sm font-medium text-gray-700">
            Google allintitle: results
          </label>
          <input
            type="number"
            id="allInTitle"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-primary focus:ring-brand-primary sm:text-sm"
            value={allInTitle}
            onChange={(e) => setAllInTitle(e.target.value)}
            placeholder="Enter allintitle: results"
          />
        </div>

        <div>
          <label htmlFor="searchVolume" className="block text-sm font-medium text-gray-700">
            Monthly Search Volume
          </label>
          <input
            type="number"
            id="searchVolume"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-primary focus:ring-brand-primary sm:text-sm"
            value={searchVolume}
            onChange={(e) => setSearchVolume(e.target.value)}
            placeholder="Enter monthly search volume"
          />
        </div>

        <button
          onClick={calculateKGR}
          className="btn-primary w-full"
        >
          Calculate KGR
        </button>

        {kgrResult !== null && (
          <div className="mt-6 p-4 bg-gray-50 rounded-md">
            <h3 className="text-lg font-medium text-gray-900">Results</h3>
            <p className="mt-2 text-sm text-gray-600">
              KGR Score: <span className="font-bold">{kgrResult.toFixed(2)}</span>
            </p>
            <p className="mt-2 text-sm text-gray-600">
              Interpretation: <span className="font-medium">{kgrInterpretation}</span>
            </p>
          </div>
        )}
      </div>
    </div>
  );
}