import React, { useState } from 'react';
import { Building2, Search, BarChart2, Download, Rocket } from 'lucide-react';

interface SaaSMetrics {
  domain: string;
  metrics: {
    domainAuthority: number;
    organicTraffic: number;
    conversionRate: number;
    mrrImpact: number;
    competitorGap: number;
  };
  opportunities: Array<{
    type: string;
    impact: 'high' | 'medium' | 'low';
    description: string;
  }>;
}

export default function SaasSEOTool() {
  const [domain, setDomain] = useState('');
  const [metrics, setMetrics] = useState<SaaSMetrics | null>(null);

  const analyzeDomain = () => {
    // Simulated SaaS SEO analysis
    setMetrics({
      domain,
      metrics: {
        domainAuthority: Math.floor(Math.random() * 30) + 40,
        organicTraffic: Math.floor(Math.random() * 50000) + 10000,
        conversionRate: (Math.random() * 3 + 1).toFixed(2),
        mrrImpact: Math.floor(Math.random() * 20000) + 5000,
        competitorGap: Math.floor(Math.random() * 50) + 20,
      },
      opportunities: [
        {
          type: 'Product Pages',
          impact: 'high',
          description: 'Optimize feature pages with user intent keywords',
        },
        {
          type: 'Content Strategy',
          impact: 'high',
          description: 'Create bottom-of-funnel content targeting decision makers',
        },
        {
          type: 'Technical SEO',
          impact: 'medium',
          description: 'Improve page load speed for better user experience',
        },
      ],
    });
  };

  const downloadReport = () => {
    if (!metrics) return;

    const reportContent = `
SaaS SEO Analysis Report
Domain: ${metrics.domain}

Key Metrics:
- Domain Authority: ${metrics.metrics.domainAuthority}
- Monthly Organic Traffic: ${metrics.metrics.organicTraffic.toLocaleString()}
- Conversion Rate: ${metrics.metrics.conversionRate}%
- Estimated MRR Impact: $${metrics.metrics.mrrImpact.toLocaleString()}
- Competitor Gap: ${metrics.metrics.competitorGap}%

Opportunities:
${metrics.opportunities.map(opp => `
${opp.type} (${opp.impact} impact)
- ${opp.description}
`).join('\n')}
    `.trim();

    const blob = new Blob([reportContent], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${domain}-saas-seo-report.txt`;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6 max-w-4xl mx-auto">
      <div className="flex items-center mb-6">
        <Rocket className="h-8 w-8 text-brand-primary mr-3" />
        <h2 className="text-2xl font-bold text-gray-900">SEO Tool for SaaS</h2>
      </div>

      <div className="space-y-6">
        <div>
          <label htmlFor="domain" className="block text-sm font-medium text-gray-700">
            SaaS Domain
          </label>
          <div className="mt-1 flex rounded-md shadow-sm">
            <input
              type="text"
              id="domain"
              className="flex-1 block w-full rounded-md border-gray-300 focus:border-brand-primary focus:ring-brand-primary sm:text-sm"
              value={domain}
              onChange={(e) => setDomain(e.target.value)}
              placeholder="Enter your SaaS domain (e.g., example.com)"
            />
            <button
              onClick={analyzeDomain}
              className="ml-3 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-brand-primary hover:bg-brand-darker focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-primary"
            >
              <Search className="h-4 w-4 mr-2" />
              Analyze
            </button>
          </div>
        </div>

        {metrics && (
          <div className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="bg-gray-50 rounded-lg p-4">
                <h3 className="text-sm font-medium text-gray-500">Domain Authority</h3>
                <p className="mt-2 text-3xl font-bold text-gray-900">{metrics.metrics.domainAuthority}</p>
              </div>
              <div className="bg-gray-50 rounded-lg p-4">
                <h3 className="text-sm font-medium text-gray-500">Monthly Traffic</h3>
                <p className="mt-2 text-3xl font-bold text-gray-900">
                  {metrics.metrics.organicTraffic.toLocaleString()}
                </p>
              </div>
              <div className="bg-gray-50 rounded-lg p-4">
                <h3 className="text-sm font-medium text-gray-500">Conversion Rate</h3>
                <p className="mt-2 text-3xl font-bold text-gray-900">{metrics.metrics.conversionRate}%</p>
              </div>
            </div>

            <div className="bg-gray-50 rounded-lg p-6">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Growth Opportunities</h3>
              <div className="space-y-4">
                {metrics.opportunities.map((opp, index) => (
                  <div key={index} className="bg-white p-4 rounded-md shadow-sm">
                    <div className="flex items-center justify-between">
                      <h4 className="font-medium text-gray-900">{opp.type}</h4>
                      <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                        opp.impact === 'high' ? 'bg-red-100 text-red-800' :
                        opp.impact === 'medium' ? 'bg-yellow-100 text-yellow-800' :
                        'bg-green-100 text-green-800'
                      }`}>
                        {opp.impact.toUpperCase()} Impact
                      </span>
                    </div>
                    <p className="mt-2 text-sm text-gray-600">{opp.description}</p>
                  </div>
                ))}
              </div>
            </div>

            <button
              onClick={downloadReport}
              className="btn-secondary w-full flex items-center justify-center"
            >
              <Download className="h-5 w-5 mr-2" />
              Download Full Report
            </button>
          </div>
        )}
      </div>
    </div>
  );
}