import React, { useState } from 'react';
import { FileText, Search, Brain, Download, CheckCircle } from 'lucide-react';

interface ContentAnalysis {
  score: number;
  semanticRelevance: number;
  readabilityScore: number;
  entityAnalysis: Array<{
    entity: string;
    type: string;
    relevance: number;
  }>;
  topicCoverage: Array<{
    topic: string;
    coverage: number;
    suggestions: string[];
  }>;
  contentStructure: {
    headings: number;
    paragraphs: number;
    sentences: number;
    readingTime: number;
  };
}

export default function SemanticContentAnalysisTool() {
  const [content, setContent] = useState('');
  const [mainTopic, setMainTopic] = useState('');
  const [analysis, setAnalysis] = useState<ContentAnalysis | null>(null);

  const analyzeContent = () => {
    // Simulated semantic content analysis
    setAnalysis({
      score: Math.floor(Math.random() * 20) + 80,
      semanticRelevance: Math.floor(Math.random() * 15) + 85,
      readabilityScore: Math.floor(Math.random() * 10) + 90,
      entityAnalysis: [
        { entity: 'SEO', type: 'Topic', relevance: 0.95 },
        { entity: 'Content Analysis', type: 'Process', relevance: 0.92 },
        { entity: 'Semantic Search', type: 'Concept', relevance: 0.88 },
        { entity: 'Google', type: 'Organization', relevance: 0.85 },
      ],
      topicCoverage: [
        {
          topic: 'Semantic Analysis',
          coverage: 0.89,
          suggestions: ['Add more examples', 'Include case studies'],
        },
        {
          topic: 'Content Optimization',
          coverage: 0.92,
          suggestions: ['Expand on best practices'],
        },
        {
          topic: 'SEO Strategy',
          coverage: 0.87,
          suggestions: ['Add implementation steps'],
        },
      ],
      contentStructure: {
        headings: 5,
        paragraphs: 12,
        sentences: 48,
        readingTime: 4,
      },
    });
  };

  const downloadReport = () => {
    if (!analysis) return;

    const reportContent = `
Best SEO Semantic Content Analysis Report

Overall Score: ${analysis.score}/100
Semantic Relevance: ${analysis.semanticRelevance}%
Readability Score: ${analysis.readabilityScore}%

Entity Analysis:
${analysis.entityAnalysis.map(entity => 
  `- ${entity.entity} (${entity.type}): ${(entity.relevance * 100).toFixed()}% relevance`
).join('\n')}

Topic Coverage:
${analysis.topicCoverage.map(topic => `
${topic.topic}:
- Coverage: ${(topic.coverage * 100).toFixed()}%
- Suggestions: ${topic.suggestions.join(', ')}
`).join('\n')}

Content Structure:
- Headings: ${analysis.contentStructure.headings}
- Paragraphs: ${analysis.contentStructure.paragraphs}
- Sentences: ${analysis.contentStructure.sentences}
- Reading Time: ${analysis.contentStructure.readingTime} minutes
    `.trim();

    const blob = new Blob([reportContent], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'semantic-content-analysis.txt';
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6 max-w-4xl mx-auto">
      <div className="flex items-center mb-6">
        <Brain className="h-8 w-8 text-brand-primary mr-3" />
        <h2 className="text-2xl font-bold text-gray-900">Best SEO Semantic Content Analysis Tool</h2>
      </div>

      <div className="space-y-6">
        <div>
          <label htmlFor="mainTopic" className="block text-sm font-medium text-gray-700">
            Main Topic or Focus Keyword
          </label>
          <input
            type="text"
            id="mainTopic"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-primary focus:ring-brand-primary sm:text-sm"
            value={mainTopic}
            onChange={(e) => setMainTopic(e.target.value)}
            placeholder="Enter your main topic or focus keyword"
          />
        </div>

        <div>
          <label htmlFor="content" className="block text-sm font-medium text-gray-700">
            Content for Analysis
          </label>
          <textarea
            id="content"
            rows={10}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-primary focus:ring-brand-primary sm:text-sm"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            placeholder="Paste your content here for semantic analysis..."
          />
        </div>

        <div className="flex gap-4">
          <button
            onClick={analyzeContent}
            className="btn-primary flex-grow flex items-center justify-center"
          >
            <Search className="h-5 w-5 mr-2" />
            Analyze Content
          </button>
          {analysis && (
            <button
              onClick={downloadReport}
              className="btn-secondary flex items-center"
            >
              <Download className="h-5 w-5 mr-2" />
              Export Report
            </button>
          )}
        </div>

        {analysis && (
          <div className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="bg-gray-50 rounded-lg p-4">
                <h3 className="text-sm font-medium text-gray-500">Overall Score</h3>
                <p className="mt-2 text-3xl font-bold text-brand-primary">{analysis.score}</p>
              </div>
              <div className="bg-gray-50 rounded-lg p-4">
                <h3 className="text-sm font-medium text-gray-500">Semantic Relevance</h3>
                <p className="mt-2 text-3xl font-bold text-brand-primary">{analysis.semanticRelevance}%</p>
              </div>
              <div className="bg-gray-50 rounded-lg p-4">
                <h3 className="text-sm font-medium text-gray-500">Readability</h3>
                <p className="mt-2 text-3xl font-bold text-brand-primary">{analysis.readabilityScore}%</p>
              </div>
            </div>

            <div className="bg-gray-50 rounded-lg p-6">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Entity Analysis</h3>
              <div className="space-y-3">
                {analysis.entityAnalysis.map((entity, index) => (
                  <div key={index} className="flex items-center justify-between">
                    <div>
                      <span className="font-medium text-gray-900">{entity.entity}</span>
                      <span className="ml-2 text-sm text-gray-500">({entity.type})</span>
                    </div>
                    <span className="text-sm text-gray-500">
                      {(entity.relevance * 100).toFixed()}% relevance
                    </span>
                  </div>
                ))}
              </div>
            </div>

            <div className="bg-gray-50 rounded-lg p-6">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Topic Coverage</h3>
              <div className="space-y-4">
                {analysis.topicCoverage.map((topic, index) => (
                  <div key={index} className="bg-white p-4 rounded-md shadow-sm">
                    <div className="flex items-center justify-between mb-2">
                      <span className="font-medium text-gray-900">{topic.topic}</span>
                      <span className="text-sm text-gray-500">
                        {(topic.coverage * 100).toFixed()}% coverage
                      </span>
                    </div>
                    <ul className="text-sm text-gray-600 space-y-1">
                      {topic.suggestions.map((suggestion, i) => (
                        <li key={i} className="flex items-center">
                          <CheckCircle className="h-4 w-4 text-brand-primary mr-2" />
                          {suggestion}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>

            <div className="bg-gray-50 rounded-lg p-6">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Content Structure</h3>
              <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                <div>
                  <span className="text-sm text-gray-500">Headings</span>
                  <p className="text-xl font-bold text-gray-900">{analysis.contentStructure.headings}</p>
                </div>
                <div>
                  <span className="text-sm text-gray-500">Paragraphs</span>
                  <p className="text-xl font-bold text-gray-900">{analysis.contentStructure.paragraphs}</p>
                </div>
                <div>
                  <span className="text-sm text-gray-500">Sentences</span>
                  <p className="text-xl font-bold text-gray-900">{analysis.contentStructure.sentences}</p>
                </div>
                <div>
                  <span className="text-sm text-gray-500">Reading Time</span>
                  <p className="text-xl font-bold text-gray-900">{analysis.contentStructure.readingTime} min</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}