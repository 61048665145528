import { Link } from 'react-router-dom';
import { Youtube, Twitter, Facebook, Instagram, Globe } from 'lucide-react';

export function Footer() {
  const socialLinks = [
    { name: 'YouTube', href: 'https://www.youtube.com/@JulianGoldieSEO/videos', icon: Youtube },
    { name: 'Twitter', href: 'https://twitter.com/JulianGoldieSEO', icon: Twitter },
    { name: 'Facebook', href: 'https://www.facebook.com/juliangoldieseo/', icon: Facebook },
    { name: 'Instagram', href: 'https://www.instagram.com/jules_goldie/', icon: Instagram },
    { name: 'Website', href: 'https://JulianGoldie.com', icon: Globe },
  ];

  return (
    <footer className="bg-white">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col items-center">
          <div className="flex space-x-6">
            {socialLinks.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="text-gray-400 hover:text-[#ff7417]"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6" />
              </a>
            ))}
          </div>
          <div className="mt-8 text-center">
            <div className="text-sm text-gray-600 space-y-2">
              <p>GOLDIE AGENCY PTE. LTD.</p>
              <p>160 Robinson Road #14-04 Singapore 068914</p>
              <p>Registration No: 202007996N</p>
              <p>TIN: 202007996N</p>
            </div>
            <p className="mt-4 text-base text-gray-400">
              © {new Date().getFullYear()} Julian Goldie SEO. All rights reserved.
            </p>
            <p className="mt-4 text-sm text-gray-500">
              Disclaimer: These tools are experimental. Please use your own judgment and common sense when implementing SEO strategies.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}