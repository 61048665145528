import React, { useState } from 'react';
import { ClipboardList, Plus, Calendar, Users, CheckSquare, Trash2 } from 'lucide-react';

interface SEOTask {
  id: string;
  title: string;
  priority: 'high' | 'medium' | 'low';
  status: 'todo' | 'in-progress' | 'completed';
  assignee: string;
  dueDate: string;
}

export default function SEOProjectManagementTool() {
  const [tasks, setTasks] = useState<SEOTask[]>([]);
  const [newTask, setNewTask] = useState({
    title: '',
    priority: 'medium' as const,
    assignee: '',
    dueDate: '',
  });

  const addTask = () => {
    if (!newTask.title) return;

    setTasks([
      ...tasks,
      {
        id: Date.now().toString(),
        ...newTask,
        status: 'todo' as const,
      },
    ]);

    setNewTask({
      title: '',
      priority: 'medium',
      assignee: '',
      dueDate: '',
    });
  };

  const updateTaskStatus = (taskId: string, status: SEOTask['status']) => {
    setTasks(tasks.map(task => 
      task.id === taskId ? { ...task, status } : task
    ));
  };

  const deleteTask = (taskId: string) => {
    setTasks(tasks.filter(task => task.id !== taskId));
  };

  const getPriorityColor = (priority: string) => {
    switch (priority) {
      case 'high': return 'text-red-500';
      case 'medium': return 'text-yellow-500';
      case 'low': return 'text-green-500';
      default: return 'text-gray-500';
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6 max-w-4xl mx-auto">
      <div className="flex items-center mb-6">
        <ClipboardList className="h-8 w-8 text-brand-primary mr-3" />
        <h2 className="text-2xl font-bold text-gray-900">SEO Project Management Tool</h2>
      </div>

      <div className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <input
            type="text"
            placeholder="Task title"
            className="rounded-md border-gray-300 shadow-sm focus:border-brand-primary focus:ring-brand-primary"
            value={newTask.title}
            onChange={(e) => setNewTask({ ...newTask, title: e.target.value })}
          />
          <select
            className="rounded-md border-gray-300 shadow-sm focus:border-brand-primary focus:ring-brand-primary"
            value={newTask.priority}
            onChange={(e) => setNewTask({ ...newTask, priority: e.target.value as any })}
          >
            <option value="high">High Priority</option>
            <option value="medium">Medium Priority</option>
            <option value="low">Low Priority</option>
          </select>
          <input
            type="text"
            placeholder="Assignee"
            className="rounded-md border-gray-300 shadow-sm focus:border-brand-primary focus:ring-brand-primary"
            value={newTask.assignee}
            onChange={(e) => setNewTask({ ...newTask, assignee: e.target.value })}
          />
          <input
            type="date"
            className="rounded-md border-gray-300 shadow-sm focus:border-brand-primary focus:ring-brand-primary"
            value={newTask.dueDate}
            onChange={(e) => setNewTask({ ...newTask, dueDate: e.target.value })}
          />
        </div>

        <button
          onClick={addTask}
          className="btn-primary w-full flex items-center justify-center"
        >
          <Plus className="h-5 w-5 mr-2" />
          Add SEO Task
        </button>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Todo Column */}
          <div className="bg-gray-50 rounded-lg p-4">
            <h3 className="text-lg font-medium text-gray-900 mb-4">To Do</h3>
            <div className="space-y-3">
              {tasks.filter(task => task.status === 'todo').map(task => (
                <div key={task.id} className="bg-white p-4 rounded-md shadow-sm">
                  <h4 className="font-medium text-gray-900">{task.title}</h4>
                  <div className="mt-2 flex items-center justify-between text-sm">
                    <span className={getPriorityColor(task.priority)}>
                      {task.priority.charAt(0).toUpperCase() + task.priority.slice(1)}
                    </span>
                    <span className="text-gray-500">{task.assignee}</span>
                  </div>
                  <div className="mt-3 flex justify-between">
                    <button
                      onClick={() => updateTaskStatus(task.id, 'in-progress')}
                      className="text-brand-primary hover:text-brand-darker"
                    >
                      Start
                    </button>
                    <button
                      onClick={() => deleteTask(task.id)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <Trash2 className="h-4 w-4" />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* In Progress Column */}
          <div className="bg-gray-50 rounded-lg p-4">
            <h3 className="text-lg font-medium text-gray-900 mb-4">In Progress</h3>
            <div className="space-y-3">
              {tasks.filter(task => task.status === 'in-progress').map(task => (
                <div key={task.id} className="bg-white p-4 rounded-md shadow-sm">
                  <h4 className="font-medium text-gray-900">{task.title}</h4>
                  <div className="mt-2 flex items-center justify-between text-sm">
                    <span className={getPriorityColor(task.priority)}>
                      {task.priority.charAt(0).toUpperCase() + task.priority.slice(1)}
                    </span>
                    <span className="text-gray-500">{task.assignee}</span>
                  </div>
                  <div className="mt-3 flex justify-between">
                    <button
                      onClick={() => updateTaskStatus(task.id, 'completed')}
                      className="text-green-500 hover:text-green-700"
                    >
                      Complete
                    </button>
                    <button
                      onClick={() => deleteTask(task.id)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <Trash2 className="h-4 w-4" />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Completed Column */}
          <div className="bg-gray-50 rounded-lg p-4">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Completed</h3>
            <div className="space-y-3">
              {tasks.filter(task => task.status === 'completed').map(task => (
                <div key={task.id} className="bg-white p-4 rounded-md shadow-sm">
                  <h4 className="font-medium text-gray-900">{task.title}</h4>
                  <div className="mt-2 flex items-center justify-between text-sm">
                    <span className={getPriorityColor(task.priority)}>
                      {task.priority.charAt(0).toUpperCase() + task.priority.slice(1)}
                    </span>
                    <span className="text-gray-500">{task.assignee}</span>
                  </div>
                  <div className="mt-3 flex justify-end">
                    <button
                      onClick={() => deleteTask(task.id)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <Trash2 className="h-4 w-4" />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}