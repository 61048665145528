import React, { useState } from 'react';
import { DollarSign, Search, TrendingUp } from 'lucide-react';

interface KeywordResult {
  keyword: string;
  searchVolume: number;
  buyingIntent: number;
  competition: number;
  profitPotential: number;
}

export default function AffiliateKeywordTool() {
  const [niche, setNiche] = useState('');
  const [mainKeyword, setMainKeyword] = useState('');
  const [results, setResults] = useState<KeywordResult[]>([]);

  const analyzeKeywords = () => {
    // Simulated keyword analysis for demonstration
    const demoResults: KeywordResult[] = [
      {
        keyword: `best ${mainKeyword} for beginners`,
        searchVolume: 2400,
        buyingIntent: 0.8,
        competition: 0.6,
        profitPotential: 0.75,
      },
      {
        keyword: `top ${mainKeyword} reviews`,
        searchVolume: 1800,
        buyingIntent: 0.9,
        competition: 0.7,
        profitPotential: 0.8,
      },
      {
        keyword: `${mainKeyword} comparison`,
        searchVolume: 1200,
        buyingIntent: 0.85,
        competition: 0.5,
        profitPotential: 0.85,
      },
    ];

    setResults(demoResults);
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6 max-w-4xl mx-auto">
      <div className="flex items-center mb-6">
        <DollarSign className="h-8 w-8 text-brand-primary mr-3" />
        <h2 className="text-2xl font-bold text-gray-900">Best Keyword Research Tool for Affiliate Marketing</h2>
      </div>

      <div className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label htmlFor="niche" className="block text-sm font-medium text-gray-700">
              Affiliate Niche
            </label>
            <input
              type="text"
              id="niche"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-primary focus:ring-brand-primary sm:text-sm"
              value={niche}
              onChange={(e) => setNiche(e.target.value)}
              placeholder="e.g., fitness equipment"
            />
          </div>
          <div>
            <label htmlFor="mainKeyword" className="block text-sm font-medium text-gray-700">
              Main Product Keyword
            </label>
            <input
              type="text"
              id="mainKeyword"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-primary focus:ring-brand-primary sm:text-sm"
              value={mainKeyword}
              onChange={(e) => setMainKeyword(e.target.value)}
              placeholder="e.g., yoga mat"
            />
          </div>
        </div>

        <button
          onClick={analyzeKeywords}
          className="btn-primary w-full flex items-center justify-center"
        >
          <Search className="h-5 w-5 mr-2" />
          Find Affiliate Keywords
        </button>

        {results.length > 0 && (
          <div className="mt-8">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Affiliate Keyword Opportunities</h3>
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Keyword
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Search Volume
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Buying Intent
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Competition
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Profit Potential
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {results.map((result, index) => (
                    <tr key={index}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {result.keyword}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {result.searchVolume.toLocaleString()}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {(result.buyingIntent * 100).toFixed()}%
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {(result.competition * 100).toFixed()}%
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {(result.profitPotential * 100).toFixed()}%
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}