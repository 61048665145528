import { Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Navigation } from './components/Navigation';
import { Footer } from './components/Footer';
import TopBanner from './components/TopBanner';
import ExitPopup from './components/ExitPopup';
import { HomePage } from './pages/HomePage';
import { KGRCalculatorPage } from './pages/KGRCalculatorPage';
import { CPCCalculatorPage } from './pages/CPCCalculatorPage';
import { KeywordCannibalizationPage } from './pages/KeywordCannibalizationPage';
import { KeywordClusteringPage } from './pages/KeywordClusteringPage';
import { KeywordMatchPage } from './pages/KeywordMatchPage';
import { AffiliateKeywordPage } from './pages/AffiliateKeywordPage';
import { EtsyKeywordPage } from './pages/EtsyKeywordPage';
import { BroadMatchPage } from './pages/BroadMatchPage';
import { PinterestKeywordPage } from './pages/PinterestKeywordPage';
import { KeywordExtractionPage } from './pages/KeywordExtractionPage';
import { KeywordConcatenationPage } from './pages/KeywordConcatenationPage';
import { EnterpriseSEOPage } from './pages/EnterpriseSEOPage';
import { SEOReportingPage } from './pages/SEOReportingPage';
import { SEOProjectManagementPage } from './pages/SEOProjectManagementPage';
import { SmallBusinessSEOPage } from './pages/SmallBusinessSEOPage';
import { AutomatedSEOReportingPage } from './pages/AutomatedSEOReportingPage';
import { SEOTaskManagementPage } from './pages/SEOTaskManagementPage';
import { SaasSEOPage } from './pages/SaasSEOPage';
import { SemanticSEOPage } from './pages/SemanticSEOPage';
import { SemanticContentAnalysisPage } from './pages/SemanticContentAnalysisPage';
import { SEOProjectManagerPage } from './pages/SEOProjectManagerPage';
import { AmazonSEOPage } from './pages/AmazonSEOPage';
import { HotelSEOPage } from './pages/HotelSEOPage';
import { EcommerceSEOPage } from './pages/EcommerceSEOPage';

export function App() {
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      <Helmet>
        <title>Best Free SEO Calculators & Tools by Julian Goldie | KGR, CPC & More</title>
        <meta name="description" content="Professional SEO calculators and tools to analyze keywords, calculate ROI, and optimize your SEO strategy. Free tools include KGR Calculator, CPC Calculator, and more." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="index, follow" />
        <meta name="googlebot" content="index, follow" />
        <link rel="canonical" href="https://bestfreeseocalculators.com" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Best Free SEO Calculators & Tools by Julian Goldie" />
        <meta property="og:description" content="Professional SEO calculators and tools to analyze keywords, calculate ROI, and optimize your SEO strategy." />
        <meta property="og:site_name" content="Best Free SEO Calculators" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Best Free SEO Calculators & Tools by Julian Goldie" />
        <meta name="twitter:description" content="Professional SEO calculators and tools to analyze keywords, calculate ROI, and optimize your SEO strategy." />
      </Helmet>
      <TopBanner />
      <Navigation />
      <main className="flex-grow">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/kgr-calculator" element={<KGRCalculatorPage />} />
          <Route path="/cpc-calculator" element={<CPCCalculatorPage />} />
          <Route path="/keyword-cannibalization" element={<KeywordCannibalizationPage />} />
          <Route path="/keyword-clustering" element={<KeywordClusteringPage />} />
          <Route path="/keyword-match" element={<KeywordMatchPage />} />
          <Route path="/affiliate-keywords" element={<AffiliateKeywordPage />} />
          <Route path="/etsy-keywords" element={<EtsyKeywordPage />} />
          <Route path="/broad-match" element={<BroadMatchPage />} />
          <Route path="/pinterest-keywords" element={<PinterestKeywordPage />} />
          <Route path="/keyword-extraction" element={<KeywordExtractionPage />} />
          <Route path="/keyword-concatenation" element={<KeywordConcatenationPage />} />
          <Route path="/enterprise-seo" element={<EnterpriseSEOPage />} />
          <Route path="/seo-reporting" element={<SEOReportingPage />} />
          <Route path="/seo-project-management" element={<SEOProjectManagementPage />} />
          <Route path="/small-business-seo" element={<SmallBusinessSEOPage />} />
          <Route path="/automated-seo-reporting" element={<AutomatedSEOReportingPage />} />
          <Route path="/seo-task-management" element={<SEOTaskManagementPage />} />
          <Route path="/saas-seo" element={<SaasSEOPage />} />
          <Route path="/semantic-seo" element={<SemanticSEOPage />} />
          <Route path="/semantic-content-analysis" element={<SemanticContentAnalysisPage />} />
          <Route path="/seo-project-manager" element={<SEOProjectManagerPage />} />
          <Route path="/amazon-seo" element={<AmazonSEOPage />} />
          <Route path="/hotel-seo" element={<HotelSEOPage />} />
          <Route path="/ecommerce-seo" element={<EcommerceSEOPage />} />
        </Routes>
      </main>
      <Footer />
      <ExitPopup />
    </div>
  );
}