import React, { useState } from 'react';
import { Search, Shuffle } from 'lucide-react';

interface BroadMatchResult {
  keyword: string;
  variations: string[];
  searchVolume: number;
  relevance: number;
}

export default function BroadMatchTool() {
  const [keyword, setKeyword] = useState('');
  const [results, setResults] = useState<BroadMatchResult[]>([]);

  const generateBroadMatches = () => {
    const words = keyword.toLowerCase().trim().split(' ');
    
    // Generate variations
    const variations = [
      // Original keyword
      keyword.trim(),
      // Word order variations
      ...words.map((_, i) => {
        const variation = [...words];
        if (i < words.length - 1) {
          [variation[i], variation[i + 1]] = [variation[i + 1], variation[i]];
        }
        return variation.join(' ');
      }),
      // Synonyms and related terms (simulated)
      ...words.map(word => {
        const synonyms = getSynonyms(word);
        return words.map(w => w === word ? synonyms[0] : w).join(' ');
      }),
    ];

    setResults([
      {
        keyword: keyword.trim(),
        variations: [...new Set(variations)], // Remove duplicates
        searchVolume: Math.floor(Math.random() * 5000) + 1000,
        relevance: Math.random() * 0.5 + 0.5,
      },
    ]);
  };

  // Simulated synonym generator
  const getSynonyms = (word: string) => {
    const synonymMap: { [key: string]: string[] } = {
      best: ['top', 'greatest', 'finest'],
      cheap: ['affordable', 'budget', 'inexpensive'],
      buy: ['purchase', 'shop', 'order'],
      guide: ['tutorial', 'handbook', 'manual'],
      review: ['analysis', 'evaluation', 'assessment'],
    };
    return synonymMap[word] || [word];
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6 max-w-4xl mx-auto">
      <div className="flex items-center mb-6">
        <Shuffle className="h-8 w-8 text-brand-primary mr-3" />
        <h2 className="text-2xl font-bold text-gray-900">Broad Match Keyword Tool</h2>
      </div>

      <div className="space-y-6">
        <div>
          <label htmlFor="keyword" className="block text-sm font-medium text-gray-700">
            Enter Your Keyword
          </label>
          <div className="mt-1 flex rounded-md shadow-sm">
            <input
              type="text"
              id="keyword"
              className="flex-1 block w-full rounded-md border-gray-300 focus:border-brand-primary focus:ring-brand-primary sm:text-sm"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              placeholder="Enter a keyword to generate broad matches"
            />
            <button
              onClick={generateBroadMatches}
              className="ml-3 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-brand-primary hover:bg-brand-darker focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-primary"
            >
              <Search className="h-4 w-4 mr-2" />
              Generate Matches
            </button>
          </div>
        </div>

        {results.map((result, index) => (
          <div key={index} className="mt-8 space-y-6">
            <div className="bg-gray-50 rounded-md p-4">
              <h3 className="text-lg font-medium text-gray-900 mb-2">Main Keyword</h3>
              <div className="flex items-center justify-between">
                <span className="text-gray-900 font-medium">{result.keyword}</span>
                <span className="text-sm text-gray-500">
                  Search Volume: {result.searchVolume.toLocaleString()}
                </span>
              </div>
            </div>

            <div className="bg-gray-50 rounded-md p-4">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Broad Match Variations</h3>
              <div className="grid gap-3">
                {result.variations.map((variation, i) => (
                  <div key={i} className="flex items-center justify-between p-2 bg-white rounded-md shadow-sm">
                    <span className="text-gray-900">{variation}</span>
                    <span className="text-sm text-gray-500">
                      Relevance: {(Math.random() * 0.5 + 0.5).toFixed(2)}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}