import React, { useState } from 'react';
import { Group, Upload } from 'lucide-react';

interface Cluster {
  mainKeyword: string;
  relatedKeywords: string[];
}

export default function KeywordClusteringTool() {
  const [keywords, setKeywords] = useState('');
  const [clusters, setClusters] = useState<Cluster[]>([]);
  const [similarity, setSimilarity] = useState(0.7);

  const handleCluster = () => {
    // Simple clustering based on common words
    const keywordList = keywords.split('\n').map(k => k.trim()).filter(k => k);
    const newClusters: Cluster[] = [];
    
    const remainingKeywords = [...keywordList];
    
    while (remainingKeywords.length > 0) {
      const mainKeyword = remainingKeywords.shift()!;
      const relatedKeywords = remainingKeywords.filter(keyword => {
        const mainWords = new Set(mainKeyword.toLowerCase().split(' '));
        const compareWords = new Set(keyword.toLowerCase().split(' '));
        const intersection = new Set([...mainWords].filter(x => compareWords.has(x)));
        const similarityScore = intersection.size / Math.max(mainWords.size, compareWords.size);
        return similarityScore >= similarity;
      });
      
      // Remove related keywords from remaining list
      relatedKeywords.forEach(keyword => {
        const index = remainingKeywords.indexOf(keyword);
        if (index > -1) {
          remainingKeywords.splice(index, 1);
        }
      });
      
      newClusters.push({
        mainKeyword,
        relatedKeywords
      });
    }
    
    setClusters(newClusters);
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6 max-w-4xl mx-auto">
      <div className="flex items-center mb-6">
        <Group className="h-8 w-8 text-brand-primary mr-3" />
        <h2 className="text-2xl font-bold text-gray-900">Free Keyword Clustering Tool</h2>
      </div>

      <div className="space-y-6">
        <div>
          <label htmlFor="keywords" className="block text-sm font-medium text-gray-700">
            Enter Keywords (one per line)
          </label>
          <textarea
            id="keywords"
            rows={8}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-primary focus:ring-brand-primary sm:text-sm"
            value={keywords}
            onChange={(e) => setKeywords(e.target.value)}
            placeholder="Enter your keywords here&#10;one keyword per line&#10;example: seo tools&#10;best seo tools&#10;free seo tools"
          />
        </div>

        <div>
          <label htmlFor="similarity" className="block text-sm font-medium text-gray-700">
            Similarity Threshold ({(similarity * 100).toFixed()}%)
          </label>
          <input
            type="range"
            id="similarity"
            min="0.1"
            max="0.9"
            step="0.1"
            value={similarity}
            onChange={(e) => setSimilarity(parseFloat(e.target.value))}
            className="mt-1 block w-full"
          />
        </div>

        <button
          onClick={handleCluster}
          className="btn-primary w-full flex items-center justify-center"
        >
          <Group className="h-5 w-5 mr-2" />
          Cluster Keywords
        </button>

        {clusters.length > 0 && (
          <div className="mt-8 space-y-6">
            <h3 className="text-lg font-medium text-gray-900">Keyword Clusters</h3>
            {clusters.map((cluster, index) => (
              <div key={index} className="bg-gray-50 rounded-md p-4">
                <h4 className="font-medium text-brand-primary">{cluster.mainKeyword}</h4>
                {cluster.relatedKeywords.length > 0 && (
                  <ul className="mt-2 space-y-1 text-sm text-gray-600">
                    {cluster.relatedKeywords.map((keyword, idx) => (
                      <li key={idx}>• {keyword}</li>
                    ))}
                  </ul>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}