import React from 'react';
import AutomatedSEOReportingTool from '../components/AutomatedSEOReportingTool';
import CTABanner from '../components/CTABanner';

export function AutomatedSEOReportingPage() {
  return (
    <div className="py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h1 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Automated SEO Reporting Tool
          </h1>
          <p className="mt-4 text-xl text-gray-500 max-w-3xl mx-auto">
            Generate automated SEO reports with AI-powered insights to streamline your SEO monitoring and analysis.
          </p>
        </div>
        
        <AutomatedSEOReportingTool />
        
        <div className="prose prose-lg max-w-3xl mx-auto mt-16">
          <h2>How to Use the Automated SEO Reporting Tool</h2>
          <p>
            Our automated SEO reporting tool revolutionizes the way you monitor and analyze your website's search engine performance. By automating the reporting process, you can save time while gaining deeper insights into your SEO progress.
          </p>

          <h3>Setting Up Automated Reports</h3>
          <p>
            Get started with automated SEO reporting in three simple steps:
          </p>

          <ol>
            <li>Enter your website domain for tracking</li>
            <li>Choose your preferred reporting frequency (daily, weekly, or monthly)</li>
            <li>Add your email address for automated report delivery</li>
          </ol>

          <h3>Understanding Your Automated Reports</h3>
          <p>
            Each automated report includes comprehensive metrics and insights:
          </p>

          <ul>
            <li><strong>Key Metrics:</strong> Track organic traffic, rankings, backlinks, and conversions</li>
            <li><strong>Traffic Trends:</strong> Monitor traffic growth and patterns over time</li>
            <li><strong>AI Insights:</strong> Receive automated recommendations and observations</li>
            <li><strong>Performance Analysis:</strong> Track key performance indicators</li>
          </ul>

          <h3>Leveraging Automated Insights</h3>
          <p>
            Make the most of your automated SEO reports:
          </p>

          <ul>
            <li>Review automated insights for quick action items</li>
            <li>Monitor trend data for strategic planning</li>
            <li>Track progress against SEO goals</li>
            <li>Identify opportunities and challenges</li>
            <li>Share reports with stakeholders</li>
          </ul>

          <h3>Customizing Your Reports</h3>
          <p>
            Tailor your automated reports to your needs:
          </p>

          <ul>
            <li>Select relevant metrics for tracking</li>
            <li>Adjust reporting frequency</li>
            <li>Customize email notifications</li>
            <li>Set up multiple report recipients</li>
            <li>Configure alert thresholds</li>
          </ul>

          <h3>Taking Action on Reports</h3>
          <p>
            Transform automated insights into results:
          </p>

          <ul>
            <li>Prioritize recommended actions</li>
            <li>Address negative trends quickly</li>
            <li>Scale successful strategies</li>
            <li>Track implementation impact</li>
            <li>Adjust strategies based on data</li>
          </ul>
        </div>
      </div>
      <div className="mt-16">
        <CTABanner />
      </div>
    </div>
  );
}