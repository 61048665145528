import React, { useState } from 'react';
import { DollarSign } from 'lucide-react';

export default function CPCCalculator() {
  const [dailyBudget, setDailyBudget] = useState('');
  const [targetClicks, setTargetClicks] = useState('');
  const [cpcResult, setCpcResult] = useState<number | null>(null);
  const [monthlyEstimate, setMonthlyEstimate] = useState<number | null>(null);

  const calculateCPC = () => {
    const budget = parseFloat(dailyBudget);
    const clicks = parseInt(targetClicks);

    if (isNaN(budget) || isNaN(clicks) || clicks === 0) {
      return;
    }

    const cpc = budget / clicks;
    const monthly = budget * 30.44; // Average days in a month

    setCpcResult(cpc);
    setMonthlyEstimate(monthly);
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6 max-w-2xl mx-auto">
      <div className="flex items-center mb-6">
        <DollarSign className="h-8 w-8 text-brand-primary mr-3" />
        <h2 className="text-2xl font-bold text-gray-900">CPC Keyword Calculator</h2>
      </div>

      <div className="space-y-6">
        <div>
          <label htmlFor="dailyBudget" className="block text-sm font-medium text-gray-700">
            Daily Ad Budget ($)
          </label>
          <input
            type="number"
            id="dailyBudget"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-primary focus:ring-brand-primary sm:text-sm"
            value={dailyBudget}
            onChange={(e) => setDailyBudget(e.target.value)}
            placeholder="Enter your daily budget"
            step="0.01"
          />
        </div>

        <div>
          <label htmlFor="targetClicks" className="block text-sm font-medium text-gray-700">
            Target Daily Clicks
          </label>
          <input
            type="number"
            id="targetClicks"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-primary focus:ring-brand-primary sm:text-sm"
            value={targetClicks}
            onChange={(e) => setTargetClicks(e.target.value)}
            placeholder="Enter target daily clicks"
          />
        </div>

        <button
          onClick={calculateCPC}
          className="btn-primary w-full"
        >
          Calculate CPC
        </button>

        {cpcResult !== null && (
          <div className="mt-6 p-4 bg-gray-50 rounded-md space-y-2">
            <h3 className="text-lg font-medium text-gray-900">Results</h3>
            <p className="text-sm text-gray-600">
              Target CPC: <span className="font-bold">${cpcResult.toFixed(2)}</span>
            </p>
            <p className="text-sm text-gray-600">
              Monthly Budget Estimate: <span className="font-bold">${monthlyEstimate?.toFixed(2)}</span>
            </p>
          </div>
        )}
      </div>
    </div>
  );
}