import React, { useState } from 'react';
import { Search, TrendingUp, ShoppingBag, BarChart } from 'lucide-react';

interface EtsyKeyword {
  keyword: string;
  searchVolume: number;
  competition: number;
  relevance: number;
  trend: 'up' | 'down' | 'stable';
}

export default function EtsyKeywordTool() {
  const [productType, setProductType] = useState('');
  const [results, setResults] = useState<EtsyKeyword[]>([]);

  const analyzeKeywords = () => {
    // Simulated keyword analysis for demonstration
    const demoResults: EtsyKeyword[] = [
      {
        keyword: `${productType} handmade`,
        searchVolume: 5200,
        competition: 0.4,
        relevance: 0.9,
        trend: 'up',
      },
      {
        keyword: `custom ${productType}`,
        searchVolume: 4800,
        competition: 0.6,
        relevance: 0.85,
        trend: 'up',
      },
      {
        keyword: `personalized ${productType}`,
        searchVolume: 3900,
        competition: 0.5,
        relevance: 0.8,
        trend: 'stable',
      },
      {
        keyword: `unique ${productType}`,
        searchVolume: 3200,
        competition: 0.3,
        relevance: 0.75,
        trend: 'up',
      },
    ];

    setResults(demoResults);
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6 max-w-4xl mx-auto">
      <div className="flex items-center mb-6">
        <ShoppingBag className="h-8 w-8 text-brand-primary mr-3" />
        <h2 className="text-2xl font-bold text-gray-900">Best Etsy Keyword Research Tool</h2>
      </div>

      <div className="space-y-6">
        <div>
          <label htmlFor="productType" className="block text-sm font-medium text-gray-700">
            Enter Your Product Type
          </label>
          <div className="mt-1 flex rounded-md shadow-sm">
            <input
              type="text"
              id="productType"
              className="flex-1 block w-full rounded-md border-gray-300 focus:border-brand-primary focus:ring-brand-primary sm:text-sm"
              value={productType}
              onChange={(e) => setProductType(e.target.value)}
              placeholder="e.g., jewelry, stickers, wall art"
            />
            <button
              onClick={analyzeKeywords}
              className="ml-3 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-brand-primary hover:bg-brand-darker focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-primary"
            >
              <Search className="h-4 w-4 mr-2" />
              Find Keywords
            </button>
          </div>
        </div>

        {results.length > 0 && (
          <div className="mt-8">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Etsy Keyword Opportunities</h3>
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Keyword
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Monthly Searches
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Competition
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Relevance
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Trend
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {results.map((result, index) => (
                    <tr key={index}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {result.keyword}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {result.searchVolume.toLocaleString()}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {(result.competition * 100).toFixed()}%
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {(result.relevance * 100).toFixed()}%
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <span className="flex items-center">
                          {result.trend === 'up' && <TrendingUp className="h-4 w-4 text-green-500 mr-1" />}
                          {result.trend === 'down' && <TrendingUp className="h-4 w-4 text-red-500 mr-1 transform rotate-180" />}
                          {result.trend === 'stable' && <BarChart className="h-4 w-4 text-yellow-500 mr-1" />}
                          {result.trend.charAt(0).toUpperCase() + result.trend.slice(1)}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}