import React, { useState } from 'react';
import { FileText, BarChart2, Download, Calendar, TrendingUp } from 'lucide-react';

interface SEOReport {
  metrics: {
    organicTraffic: number;
    rankings: number;
    backlinks: number;
    conversions: number;
    pageSpeed: number;
  };
  topKeywords: Array<{
    keyword: string;
    position: number;
    traffic: number;
    change: number;
  }>;
  performance: {
    mobile: number;
    desktop: number;
    core: number;
  };
}

export default function SEOReportingTool() {
  const [domain, setDomain] = useState('');
  const [dateRange, setDateRange] = useState('30');
  const [report, setReport] = useState<SEOReport | null>(null);

  const generateReport = () => {
    // Simulated report generation
    setReport({
      metrics: {
        organicTraffic: Math.floor(Math.random() * 100000) + 50000,
        rankings: Math.floor(Math.random() * 1000) + 500,
        backlinks: Math.floor(Math.random() * 5000) + 1000,
        conversions: Math.floor(Math.random() * 1000) + 100,
        pageSpeed: Math.floor(Math.random() * 20) + 80,
      },
      topKeywords: [
        {
          keyword: "best seo tools",
          position: 3,
          traffic: 2500,
          change: 2,
        },
        {
          keyword: "seo software",
          position: 5,
          traffic: 1800,
          change: -1,
        },
        {
          keyword: "rank tracker",
          position: 2,
          traffic: 1500,
          change: 4,
        },
      ],
      performance: {
        mobile: 85,
        desktop: 92,
        core: 88,
      },
    });
  };

  const downloadReport = () => {
    if (!report) return;

    const reportData = `
SEO Performance Report
Domain: ${domain}
Date Range: Last ${dateRange} days

Key Metrics:
- Organic Traffic: ${report.metrics.organicTraffic.toLocaleString()}
- Keyword Rankings: ${report.metrics.rankings.toLocaleString()}
- Total Backlinks: ${report.metrics.backlinks.toLocaleString()}
- Conversions: ${report.metrics.conversions.toLocaleString()}
- Page Speed Score: ${report.metrics.pageSpeed}

Top Performing Keywords:
${report.topKeywords.map(k => `- ${k.keyword}: Position ${k.position} (${k.change >= 0 ? '+' : ''}${k.change})`).join('\n')}

Performance Metrics:
- Mobile Score: ${report.performance.mobile}%
- Desktop Score: ${report.performance.desktop}%
- Core Web Vitals: ${report.performance.core}%
    `.trim();

    const blob = new Blob([reportData], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${domain}-seo-report.txt`;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6 max-w-4xl mx-auto">
      <div className="flex items-center mb-6">
        <BarChart2 className="h-8 w-8 text-brand-primary mr-3" />
        <h2 className="text-2xl font-bold text-gray-900">Best SEO Reporting Tool</h2>
      </div>

      <div className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label htmlFor="domain" className="block text-sm font-medium text-gray-700">
              Website Domain
            </label>
            <input
              type="text"
              id="domain"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-primary focus:ring-brand-primary sm:text-sm"
              value={domain}
              onChange={(e) => setDomain(e.target.value)}
              placeholder="Enter your domain (e.g., example.com)"
            />
          </div>
          <div>
            <label htmlFor="dateRange" className="block text-sm font-medium text-gray-700">
              Date Range
            </label>
            <select
              id="dateRange"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-primary focus:ring-brand-primary sm:text-sm"
              value={dateRange}
              onChange={(e) => setDateRange(e.target.value)}
            >
              <option value="7">Last 7 days</option>
              <option value="30">Last 30 days</option>
              <option value="90">Last 90 days</option>
            </select>
          </div>
        </div>

        <button
          onClick={generateReport}
          className="btn-primary w-full flex items-center justify-center"
        >
          <FileText className="h-5 w-5 mr-2" />
          Generate SEO Report
        </button>

        {report && (
          <div className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="bg-gray-50 rounded-lg p-4">
                <div className="flex items-center justify-between">
                  <h3 className="text-sm font-medium text-gray-500">Organic Traffic</h3>
                  <span className="text-2xl font-bold text-gray-900">
                    {report.metrics.organicTraffic.toLocaleString()}
                  </span>
                </div>
              </div>
              <div className="bg-gray-50 rounded-lg p-4">
                <div className="flex items-center justify-between">
                  <h3 className="text-sm font-medium text-gray-500">Rankings</h3>
                  <span className="text-2xl font-bold text-gray-900">
                    {report.metrics.rankings.toLocaleString()}
                  </span>
                </div>
              </div>
              <div className="bg-gray-50 rounded-lg p-4">
                <div className="flex items-center justify-between">
                  <h3 className="text-sm font-medium text-gray-500">Backlinks</h3>
                  <span className="text-2xl font-bold text-gray-900">
                    {report.metrics.backlinks.toLocaleString()}
                  </span>
                </div>
              </div>
            </div>

            <div className="bg-gray-50 rounded-lg p-6">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Top Performing Keywords</h3>
              <div className="space-y-4">
                {report.topKeywords.map((keyword, index) => (
                  <div key={index} className="flex items-center justify-between">
                    <span className="text-gray-900">{keyword.keyword}</span>
                    <div className="flex items-center space-x-4">
                      <span className="text-gray-600">Position {keyword.position}</span>
                      <span className={`flex items-center ${keyword.change >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                        <TrendingUp className={`h-4 w-4 mr-1 ${keyword.change < 0 ? 'transform rotate-180' : ''}`} />
                        {keyword.change >= 0 ? '+' : ''}{keyword.change}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <button
              onClick={downloadReport}
              className="btn-secondary w-full flex items-center justify-center"
            >
              <Download className="h-5 w-5 mr-2" />
              Download Full Report
            </button>
          </div>
        )}
      </div>
    </div>
  );
}