import React from 'react';
import CPCCalculator from '../components/CPCCalculator';
import CTABanner from '../components/CTABanner';

export function CPCCalculatorPage() {
  return (
    <div className="py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h1 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            CPC Keyword Calculator
          </h1>
          <p className="mt-4 text-xl text-gray-500 max-w-3xl mx-auto">
            Calculate and optimize your Cost Per Click (CPC) for better PPC campaign performance.
          </p>
        </div>
        <CPCCalculator />
        <div className="prose prose-lg max-w-3xl mx-auto mt-16">
          <h2>How to Use the CPC Calculator</h2>
          <p>
            The CPC Calculator helps you determine the optimal cost per click for your PPC campaigns. Follow these steps:
          </p>
          <ol>
            <li>Enter your daily advertising budget</li>
            <li>Input your target number of daily clicks</li>
            <li>The calculator will show your target CPC and monthly budget estimate</li>
          </ol>
          <h2>Optimizing Your CPC</h2>
          <ul>
            <li>Consider your conversion rate and customer lifetime value</li>
            <li>Monitor and adjust based on campaign performance</li>
            <li>Test different bid strategies to find the optimal CPC</li>
          </ul>
        </div>
      </div>
      <div className="mt-16">
        <CTABanner />
      </div>
    </div>
  );
}