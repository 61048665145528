import React from 'react';
import SEOProjectManagerTool from '../components/SEOProjectManagerTool';
import CTABanner from '../components/CTABanner';

export function SEOProjectManagerPage() {
  return (
    <div className="py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h1 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            SEO Project Manager Tool
          </h1>
          <p className="mt-4 text-xl text-gray-500 max-w-3xl mx-auto">
            Efficiently manage and track your SEO projects with our comprehensive project management tool.
          </p>
        </div>
        
        <SEOProjectManagerTool />
        
        <div className="prose prose-lg max-w-3xl mx-auto mt-16">
          <h2>How to Use the SEO Project Manager Tool</h2>
          <p>
            Our SEO project manager tool helps you organize, track, and optimize your SEO projects effectively. Whether you're managing multiple clients or handling in-house SEO initiatives, this tool streamlines your project management workflow.
          </p>

          <h3>Getting Started with SEO Project Management</h3>
          <p>
            The tool provides a comprehensive project management solution specifically designed for SEO projects:
          </p>
          
          <ul>
            <li>Create and manage SEO projects</li>
            <li>Set priorities and deadlines</li>
            <li>Track progress through different stages</li>
            <li>Monitor team performance</li>
            <li>Organize tasks by project phase</li>
          </ul>

          <h3>Creating and Managing SEO Projects</h3>
          <p>
            Follow these steps to manage your SEO projects efficiently:
          </p>

          <ol>
            <li>Enter project details including name and description</li>
            <li>Assign team members and set deadlines</li>
            <li>Prioritize projects based on importance</li>
            <li>Track progress through workflow stages</li>
            <li>Monitor completion and results</li>
          </ol>

          <h3>Project Workflow Stages</h3>
          <p>
            The tool uses a four-stage workflow system:
          </p>

          <ul>
            <li><strong>Planning:</strong> Initial project setup and strategy</li>
            <li><strong>In Progress:</strong> Active implementation phase</li>
            <li><strong>Review:</strong> Quality assurance and testing</li>
            <li><strong>Completed:</strong> Successfully finished projects</li>
          </ul>

          <h3>Best Practices for SEO Project Management</h3>
          <p>
            Follow these guidelines for optimal results:
          </p>

          <ul>
            <li>Break down large SEO initiatives into manageable projects</li>
            <li>Set realistic deadlines for each phase</li>
            <li>Assign clear responsibilities to team members</li>
            <li>Regular progress monitoring and updates</li>
            <li>Maintain clear communication channels</li>
          </ul>

          <h3>Advanced Project Management Features</h3>
          <p>
            Take advantage of advanced capabilities:
          </p>

          <ul>
            <li>Priority-based project organization</li>
            <li>Team member assignment and tracking</li>
            <li>Deadline management and reminders</li>
            <li>Progress tracking and reporting</li>
            <li>Project filtering and organization</li>
          </ul>
        </div>
      </div>
      <div className="mt-16">
        <CTABanner />
      </div>
    </div>
  );
}