import React from 'react';
import KeywordMatchTool from '../components/KeywordMatchTool';
import CTABanner from '../components/CTABanner';

export function KeywordMatchPage() {
  return (
    <div className="py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h1 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Keyword Match Tool
          </h1>
          <p className="mt-4 text-xl text-gray-500 max-w-3xl mx-auto">
            Analyze and generate different keyword match types for better SEO and PPC targeting.
          </p>
        </div>
        
        <KeywordMatchTool />
        
        <div className="prose prose-lg max-w-3xl mx-auto mt-16">
          <h2>Understanding Keyword Match Types</h2>
          <p>
            Keyword match types are crucial for both SEO and PPC campaigns. They determine how your keywords trigger your ads or content to appear in search results. Our keyword match tool helps you analyze and generate different match types for more effective targeting.
          </p>

          <h3>Types of Keyword Matches</h3>
          <h4>Exact Match</h4>
          <p>
            Exact match keywords, denoted by [keyword] or "keyword", trigger only when someone searches for your exact keyword phrase, without any additional words before, after, or in between. This match type provides the most control but may limit your reach.
          </p>

          <h4>Phrase Match</h4>
          <p>
            Phrase match keywords, shown as "keyword", allow your content to appear when someone searches for your exact keyword phrase with additional words before or after it. This provides a good balance between reach and relevance.
          </p>

          <h4>Broad Match</h4>
          <p>
            Broad match keywords trigger for searches that include any or all words in your keyword phrase, in any order. This provides the widest reach but may result in less relevant matches.
          </p>

          <h3>How to Use the Keyword Match Tool</h3>
          <ol>
            <li>Enter your target keyword in the input field</li>
            <li>Click the "Analyze" button to generate match variations</li>
            <li>Review the different match types and their variations</li>
            <li>Use these variations in your SEO and PPC campaigns</li>
          </ol>

          <h3>Best Practices for Keyword Matching</h3>
          <ul>
            <li>Start with exact match keywords for highest relevance</li>
            <li>Use phrase match to capture relevant variations</li>
            <li>Monitor broad match performance closely</li>
            <li>Test different match types to find the optimal balance</li>
            <li>Regularly review and refine your keyword matches</li>
          </ul>

          <h3>Implementing Match Types in Your Strategy</h3>
          <p>
            For the best results, implement a mixed strategy using different match types:
          </p>
          
          <ul>
            <li>Use exact match for your highest-converting keywords</li>
            <li>Implement phrase match for closely related variations</li>
            <li>Test broad match with careful monitoring</li>
            <li>Regularly analyze performance metrics</li>
            <li>Adjust your strategy based on data</li>
          </ul>
        </div>
      </div>
      <div className="mt-16">
        <CTABanner />
      </div>
    </div>
  );
}