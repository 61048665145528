import { Link } from 'react-router-dom';
import { Calculator, Search, AlertTriangle, DollarSign, Group, Hash, ShoppingBag, Image, FileText, Building2, BarChart2, ClipboardList, Building, RefreshCw, ListTodo } from 'lucide-react';

export function HomePage() {
  const categories = [
    {
      name: 'Keyword Research',
      tools: [
        {
          name: 'KGR Calculator',
          description: 'Calculate Keyword Golden Ratio for better SEO targeting',
          href: '/kgr-calculator',
          icon: Search,
        },
        {
          name: 'Affiliate Keywords',
          description: 'Find profitable keywords for affiliate marketing',
          href: '/affiliate-keywords',
          icon: ShoppingBag,
        },
        {
          name: 'Etsy Keywords',
          description: 'Discover high-converting Etsy shop keywords',
          href: '/etsy-keywords',
          icon: ShoppingBag,
        },
        {
          name: 'Pinterest Keywords',
          description: 'Optimize your Pinterest content strategy',
          href: '/pinterest-keywords',
          icon: Image,
        },
      ],
    },
    {
      name: 'Content Analysis',
      tools: [
        {
          name: 'Keyword Cannibalization',
          description: 'Identify and fix competing content issues',
          href: '/keyword-cannibalization',
          icon: AlertTriangle,
        },
        {
          name: 'Keyword Extraction',
          description: 'Extract and analyze keywords from content',
          href: '/keyword-extraction',
          icon: FileText,
        },
      ],
    },
    {
      name: 'Keyword Tools',
      tools: [
        {
          name: 'Clustering Tool',
          description: 'Group and organize related keywords',
          href: '/keyword-clustering',
          icon: Group,
        },
        {
          name: 'Match Tool',
          description: 'Analyze keyword match types',
          href: '/keyword-match',
          icon: Hash,
        },
        {
          name: 'Broad Match Tool',
          description: 'Generate broad match variations',
          href: '/broad-match',
          icon: Calculator,
        },
        {
          name: 'CPC Calculator',
          description: 'Calculate cost per click metrics',
          href: '/cpc-calculator',
          icon: DollarSign,
        },
        {
          name: 'Concatenation Tool',
          description: 'Combine keywords effectively',
          href: '/keyword-concatenation',
          icon: Calculator,
        },
      ],
    },
    {
      name: 'Business Tools',
      tools: [
        {
          name: 'Enterprise SEO',
          description: 'Complete enterprise SEO solution',
          href: '/enterprise-seo',
          icon: Building2,
        },
        {
          name: 'Small Business SEO',
          description: 'SEO tools for small businesses',
          href: '/small-business-seo',
          icon: Building,
        },
        {
          name: 'SEO Reporting',
          description: 'Generate comprehensive SEO reports',
          href: '/seo-reporting',
          icon: BarChart2,
        },
        {
          name: 'Automated Reporting',
          description: 'Automate your SEO reporting workflow',
          href: '/automated-seo-reporting',
          icon: RefreshCw,
        },
        {
          name: 'Project Management',
          description: 'Manage SEO projects efficiently',
          href: '/seo-project-management',
          icon: ClipboardList,
        },
        {
          name: 'Task Management',
          description: 'Track and organize SEO tasks',
          href: '/seo-task-management',
          icon: ListTodo,
        },
      ],
    },
  ];

  return (
    <div className="py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
            SEO Calculators by Julian Goldie
          </h1>
          <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            Professional tools to improve your SEO strategy and boost your rankings
          </p>
          <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
            <div className="rounded-md shadow">
              <a
                href="https://go.juliangoldie.com/strategy-session"
                className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-[#ff7417] hover:bg-[#cf5a00] md:py-4 md:text-lg md:px-10"
                target="_blank"
                rel="noopener noreferrer"
              >
                Book Free Strategy Session
              </a>
            </div>
          </div>
        </div>

        <div className="mt-20 space-y-16">
          {categories.map((category) => (
            <div key={category.name}>
              <h2 className="text-2xl font-bold text-gray-900 mb-8">{category.name}</h2>
              <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                {category.tools.map((tool) => (
                  <Link
                    key={tool.name}
                    to={tool.href}
                    className="relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-[#ff7417] rounded-lg shadow-lg hover:shadow-xl transition-all duration-200"
                  >
                    <div>
                      <span className="rounded-lg inline-flex p-3 bg-[#ff7417]/10 text-[#ff7417] ring-4 ring-white">
                        <tool.icon className="h-6 w-6" aria-hidden="true" />
                      </span>
                    </div>
                    <div className="mt-8">
                      <h3 className="text-lg font-medium">
                        <span className="absolute inset-0" aria-hidden="true" />
                        {tool.name}
                      </h3>
                      <p className="mt-2 text-sm text-gray-500">
                        {tool.description}
                      </p>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}