import React from 'react';
import KeywordExtractionTool from '../components/KeywordExtractionTool';
import CTABanner from '../components/CTABanner';

export function KeywordExtractionPage() {
  return (
    <div className="py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h1 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Free Keyword Extraction Tool
          </h1>
          <p className="mt-4 text-xl text-gray-500 max-w-3xl mx-auto">
            Extract and analyze keywords from any content to optimize your SEO strategy.
          </p>
        </div>
        
        <KeywordExtractionTool />
        
        <div className="prose prose-lg max-w-3xl mx-auto mt-16">
          <h2>How to Use the Free Keyword Extraction Tool</h2>
          <p>
            Our free keyword extraction tool helps you identify and analyze the most important keywords in your content. Whether you're optimizing existing content or planning new pieces, this tool provides valuable insights into your keyword usage and content focus.
          </p>

          <h3>Getting Started with Keyword Extraction</h3>
          <p>
            Follow these simple steps to extract keywords from your content:
          </p>

          <ol>
            <li>Paste your content into the text area</li>
            <li>Set the minimum keyword frequency threshold</li>
            <li>Click "Extract Keywords" to analyze your content</li>
            <li>Review the extracted keywords and their metrics</li>
            <li>Export the results to CSV if needed</li>
          </ol>

          <h3>Understanding Keyword Metrics</h3>
          <p>
            The tool provides several important metrics for each extracted keyword:
          </p>

          <ul>
            <li><strong>Frequency:</strong> How many times the keyword appears in your content</li>
            <li><strong>Density:</strong> The percentage of total words represented by this keyword</li>
            <li><strong>Importance Score:</strong> A weighted score based on frequency and other factors</li>
          </ul>

          <h3>Optimizing Your Content with Extracted Keywords</h3>
          <p>
            Once you have your keyword analysis, use these strategies to optimize your content:
          </p>

          <ul>
            <li>Identify your most frequent and important keywords</li>
            <li>Ensure primary keywords have appropriate density (2-3%)</li>
            <li>Look for opportunities to include related keywords</li>
            <li>Balance keyword usage for natural readability</li>
            <li>Use extracted keywords in headings and meta descriptions</li>
          </ul>

          <h3>Advanced Keyword Extraction Tips</h3>
          <p>
            Get more from your keyword extraction analysis:
          </p>

          <ul>
            <li>Compare keyword distributions across different content pieces</li>
            <li>Use extracted keywords to identify content themes</li>
            <li>Find gaps in your keyword coverage</li>
            <li>Monitor keyword usage trends over time</li>
            <li>Optimize content based on importance scores</li>
          </ul>

          <h3>Best Practices for Keyword Implementation</h3>
          <p>
            Follow these guidelines when implementing extracted keywords:
          </p>

          <ul>
            <li>Maintain natural language flow</li>
            <li>Avoid keyword stuffing</li>
            <li>Use variations of important keywords</li>
            <li>Place keywords strategically in your content</li>
            <li>Monitor the impact on your SEO performance</li>
          </ul>
        </div>
      </div>
      <div className="mt-16">
        <CTABanner />
      </div>
    </div>
  );
}