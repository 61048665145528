import React, { useState } from 'react';
import { Combine, Download } from 'lucide-react';

interface ConcatenationResult {
  keyword: string;
  wordCount: number;
  type: string;
}

export default function KeywordConcatenationTool() {
  const [prefixes, setPrefixes] = useState('');
  const [mainKeywords, setMainKeywords] = useState('');
  const [suffixes, setSuffixes] = useState('');
  const [results, setResults] = useState<ConcatenationResult[]>([]);

  const concatenateKeywords = () => {
    const prefixList = prefixes.split('\n').map(p => p.trim()).filter(Boolean);
    const mainList = mainKeywords.split('\n').map(k => k.trim()).filter(Boolean);
    const suffixList = suffixes.split('\n').map(s => s.trim()).filter(Boolean);

    const combinations: ConcatenationResult[] = [];

    // Generate all possible combinations
    if (mainList.length > 0) {
      // Main keywords only
      mainList.forEach(main => {
        combinations.push({
          keyword: main,
          wordCount: main.split(' ').length,
          type: 'Main'
        });
      });

      // Prefix + Main
      if (prefixList.length > 0) {
        prefixList.forEach(prefix => {
          mainList.forEach(main => {
            const keyword = `${prefix} ${main}`.trim();
            combinations.push({
              keyword,
              wordCount: keyword.split(' ').length,
              type: 'Prefix + Main'
            });
          });
        });
      }

      // Main + Suffix
      if (suffixList.length > 0) {
        mainList.forEach(main => {
          suffixList.forEach(suffix => {
            const keyword = `${main} ${suffix}`.trim();
            combinations.push({
              keyword,
              wordCount: keyword.split(' ').length,
              type: 'Main + Suffix'
            });
          });
        });
      }

      // Prefix + Main + Suffix
      if (prefixList.length > 0 && suffixList.length > 0) {
        prefixList.forEach(prefix => {
          mainList.forEach(main => {
            suffixList.forEach(suffix => {
              const keyword = `${prefix} ${main} ${suffix}`.trim();
              combinations.push({
                keyword,
                wordCount: keyword.split(' ').length,
                type: 'Full Combination'
              });
            });
          });
        });
      }
    }

    setResults(combinations);
  };

  const downloadCSV = () => {
    const csvContent = [
      ['Keyword', 'Word Count', 'Type'],
      ...results.map(r => [r.keyword, r.wordCount, r.type])
    ].map(row => row.join(',')).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'concatenated-keywords.csv';
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6 max-w-4xl mx-auto">
      <div className="flex items-center mb-6">
        <Combine className="h-8 w-8 text-brand-primary mr-3" />
        <h2 className="text-2xl font-bold text-gray-900">Keyword Concatenation Tool</h2>
      </div>

      <div className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Prefixes (Optional)
            </label>
            <textarea
              rows={5}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-primary focus:ring-brand-primary sm:text-sm"
              value={prefixes}
              onChange={(e) => setPrefixes(e.target.value)}
              placeholder="Enter prefixes&#10;One per line&#10;e.g., best&#10;top&#10;cheap"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Main Keywords (Required)
            </label>
            <textarea
              rows={5}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-primary focus:ring-brand-primary sm:text-sm"
              value={mainKeywords}
              onChange={(e) => setMainKeywords(e.target.value)}
              placeholder="Enter main keywords&#10;One per line&#10;e.g., seo tools&#10;keyword research&#10;backlink checker"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Suffixes (Optional)
            </label>
            <textarea
              rows={5}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-primary focus:ring-brand-primary sm:text-sm"
              value={suffixes}
              onChange={(e) => setSuffixes(e.target.value)}
              placeholder="Enter suffixes&#10;One per line&#10;e.g., online&#10;software&#10;tool"
            />
          </div>
        </div>

        <div className="flex gap-4">
          <button
            onClick={concatenateKeywords}
            className="btn-primary flex-grow flex items-center justify-center"
          >
            <Combine className="h-5 w-5 mr-2" />
            Generate Combinations
          </button>
          {results.length > 0 && (
            <button
              onClick={downloadCSV}
              className="btn-secondary flex items-center"
            >
              <Download className="h-5 w-5 mr-2" />
              Export CSV
            </button>
          )}
        </div>

        {results.length > 0 && (
          <div className="mt-8">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Generated Keywords ({results.length})</h3>
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Keyword
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Word Count
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Type
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {results.map((result, index) => (
                    <tr key={index}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {result.keyword}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {result.wordCount}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {result.type}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}