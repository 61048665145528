import React, { useState } from 'react';
import { Search, AlertTriangle } from 'lucide-react';

interface KeywordData {
  url: string;
  position: string;
}

export default function KeywordCannibalizationTool() {
  const [keyword, setKeyword] = useState('');
  const [urls, setUrls] = useState<KeywordData[]>([
    { url: '', position: '' },
    { url: '', position: '' },
  ]);
  const [analyzed, setAnalyzed] = useState(false);

  const addUrlField = () => {
    setUrls([...urls, { url: '', position: '' }]);
  };

  const handleUrlChange = (index: number, field: keyof KeywordData, value: string) => {
    const newUrls = [...urls];
    newUrls[index][field] = value;
    setUrls(newUrls);
  };

  const removeUrl = (index: number) => {
    if (urls.length > 2) {
      const newUrls = urls.filter((_, i) => i !== index);
      setUrls(newUrls);
    }
  };

  const analyzeKeyword = () => {
    setAnalyzed(true);
  };

  const hasMultipleRankings = urls.filter(url => url.url && url.position).length > 1;

  return (
    <div className="bg-white rounded-lg shadow-lg p-6 max-w-2xl mx-auto">
      <div className="flex items-center mb-6">
        <Search className="h-8 w-8 text-brand-primary mr-3" />
        <h2 className="text-2xl font-bold text-gray-900">Keyword Cannibalization Tool</h2>
      </div>

      <div className="space-y-6">
        <div>
          <label htmlFor="keyword" className="block text-sm font-medium text-gray-700">
            Target Keyword
          </label>
          <input
            type="text"
            id="keyword"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-primary focus:ring-brand-primary sm:text-sm"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            placeholder="Enter your target keyword"
          />
        </div>

        <div className="space-y-4">
          <label className="block text-sm font-medium text-gray-700">
            URLs and Rankings
          </label>
          {urls.map((url, index) => (
            <div key={index} className="flex gap-4">
              <div className="flex-grow">
                <input
                  type="text"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-primary focus:ring-brand-primary sm:text-sm"
                  value={url.url}
                  onChange={(e) => handleUrlChange(index, 'url', e.target.value)}
                  placeholder="Enter URL"
                />
              </div>
              <div className="w-24">
                <input
                  type="number"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-primary focus:ring-brand-primary sm:text-sm"
                  value={url.position}
                  onChange={(e) => handleUrlChange(index, 'position', e.target.value)}
                  placeholder="Rank"
                />
              </div>
              {urls.length > 2 && (
                <button
                  onClick={() => removeUrl(index)}
                  className="text-red-500 hover:text-red-700"
                >
                  ×
                </button>
              )}
            </div>
          ))}
        </div>

        <div className="flex gap-4">
          <button onClick={addUrlField} className="btn-secondary">
            Add Another URL
          </button>
          <button onClick={analyzeKeyword} className="btn-primary flex-grow">
            Analyze Cannibalization
          </button>
        </div>

        {analyzed && (
          <div className="mt-6 p-4 bg-gray-50 rounded-md">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Analysis Results</h3>
            {hasMultipleRankings ? (
              <div className="flex items-start space-x-3 text-amber-600">
                <AlertTriangle className="h-5 w-5 mt-0.5" />
                <div>
                  <p className="font-medium">Keyword Cannibalization Detected</p>
                  <p className="mt-1 text-sm">
                    Multiple pages are ranking for "{keyword}". Consider consolidating content or using canonical tags.
                  </p>
                </div>
              </div>
            ) : (
              <p className="text-green-600">
                No cannibalization detected. Your content structure for this keyword appears optimal.
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
}