import React, { useState } from 'react';
import { ShoppingCart, Search, BarChart2, Download } from 'lucide-react';

interface AmazonKeywordAnalysis {
  keyword: string;
  metrics: {
    searchVolume: number;
    competition: number;
    buyingIntent: number;
    relevance: number;
  };
  suggestions: Array<{
    keyword: string;
    searchVolume: number;
    difficulty: number;
  }>;
  optimization: {
    title: string;
    bullets: string[];
    description: string;
  };
}

export default function AmazonSEOTool() {
  const [keyword, setKeyword] = useState('');
  const [category, setCategory] = useState('all');
  const [analysis, setAnalysis] = useState<AmazonKeywordAnalysis | null>(null);

  const analyzeKeyword = () => {
    // Simulated Amazon SEO analysis
    setAnalysis({
      keyword,
      metrics: {
        searchVolume: Math.floor(Math.random() * 50000) + 5000,
        competition: Math.random() * 0.8 + 0.2,
        buyingIntent: Math.random() * 0.6 + 0.4,
        relevance: Math.random() * 0.5 + 0.5,
      },
      suggestions: [
        {
          keyword: `best ${keyword}`,
          searchVolume: Math.floor(Math.random() * 20000) + 2000,
          difficulty: Math.random() * 0.7,
        },
        {
          keyword: `${keyword} for sale`,
          searchVolume: Math.floor(Math.random() * 15000) + 1000,
          difficulty: Math.random() * 0.6,
        },
        {
          keyword: `${keyword} review`,
          searchVolume: Math.floor(Math.random() * 10000) + 500,
          difficulty: Math.random() * 0.5,
        },
      ],
      optimization: {
        title: `Premium ${keyword} - Top Rated Amazon Choice Product`,
        bullets: [
          'PREMIUM QUALITY: Made with highest grade materials',
          'SATISFACTION GUARANTEED: 100% money-back guarantee',
          'FAST SHIPPING: Prime delivery available',
        ],
        description: `Discover our premium ${keyword} designed for maximum satisfaction. Perfect for all your needs.`,
      },
    });
  };

  const downloadReport = () => {
    if (!analysis) return;

    const reportContent = `
Best Amazon SEO Tool Analysis Report

Keyword: ${analysis.keyword}
Category: ${category}

Key Metrics:
- Search Volume: ${analysis.metrics.searchVolume.toLocaleString()}
- Competition: ${(analysis.metrics.competition * 100).toFixed()}%
- Buying Intent: ${(analysis.metrics.buyingIntent * 100).toFixed()}%
- Relevance: ${(analysis.metrics.relevance * 100).toFixed()}%

Suggested Keywords:
${analysis.suggestions.map(s => 
  `- ${s.keyword}
    Volume: ${s.searchVolume.toLocaleString()}
    Difficulty: ${(s.difficulty * 100).toFixed()}%`
).join('\n')}

Listing Optimization:
Title: ${analysis.optimization.title}

Bullet Points:
${analysis.optimization.bullets.map(b => `- ${b}`).join('\n')}

Description:
${analysis.optimization.description}
    `.trim();

    const blob = new Blob([reportContent], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `amazon-seo-analysis.txt`;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6 max-w-4xl mx-auto">
      <div className="flex items-center mb-6">
        <ShoppingCart className="h-8 w-8 text-brand-primary mr-3" />
        <h2 className="text-2xl font-bold text-gray-900">Best Amazon SEO Tool</h2>
      </div>

      <div className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label htmlFor="keyword" className="block text-sm font-medium text-gray-700">
              Product Keyword
            </label>
            <input
              type="text"
              id="keyword"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-primary focus:ring-brand-primary sm:text-sm"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              placeholder="Enter your product keyword"
            />
          </div>
          <div>
            <label htmlFor="category" className="block text-sm font-medium text-gray-700">
              Amazon Category
            </label>
            <select
              id="category"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-primary focus:ring-brand-primary sm:text-sm"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              <option value="all">All Categories</option>
              <option value="home">Home & Kitchen</option>
              <option value="electronics">Electronics</option>
              <option value="fashion">Fashion</option>
              <option value="sports">Sports & Outdoors</option>
            </select>
          </div>
        </div>

        <div className="flex gap-4">
          <button
            onClick={analyzeKeyword}
            className="btn-primary flex-grow flex items-center justify-center"
          >
            <Search className="h-5 w-5 mr-2" />
            Analyze Keyword
          </button>
          {analysis && (
            <button
              onClick={downloadReport}
              className="btn-secondary flex items-center"
            >
              <Download className="h-5 w-5 mr-2" />
              Export Report
            </button>
          )}
        </div>

        {analysis && (
          <div className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              <div className="bg-gray-50 rounded-lg p-4">
                <h3 className="text-sm font-medium text-gray-500">Search Volume</h3>
                <p className="mt-2 text-3xl font-bold text-gray-900">
                  {analysis.metrics.searchVolume.toLocaleString()}
                </p>
              </div>
              <div className="bg-gray-50 rounded-lg p-4">
                <h3 className="text-sm font-medium text-gray-500">Competition</h3>
                <p className="mt-2 text-3xl font-bold text-gray-900">
                  {(analysis.metrics.competition * 100).toFixed()}%
                </p>
              </div>
              <div className="bg-gray-50 rounded-lg p-4">
                <h3 className="text-sm font-medium text-gray-500">Buying Intent</h3>
                <p className="mt-2 text-3xl font-bold text-gray-900">
                  {(analysis.metrics.buyingIntent * 100).toFixed()}%
                </p>
              </div>
              <div className="bg-gray-50 rounded-lg p-4">
                <h3 className="text-sm font-medium text-gray-500">Relevance</h3>
                <p className="mt-2 text-3xl font-bold text-gray-900">
                  {(analysis.metrics.relevance * 100).toFixed()}%
                </p>
              </div>
            </div>

            <div className="bg-gray-50 rounded-lg p-6">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Keyword Suggestions</h3>
              <div className="space-y-4">
                {analysis.suggestions.map((suggestion, index) => (
                  <div key={index} className="flex items-center justify-between bg-white p-4 rounded-md shadow-sm">
                    <div>
                      <span className="font-medium text-gray-900">{suggestion.keyword}</span>
                      <p className="text-sm text-gray-500">
                        Volume: {suggestion.searchVolume.toLocaleString()}
                      </p>
                    </div>
                    <span className="text-sm text-gray-500">
                      Difficulty: {(suggestion.difficulty * 100).toFixed()}%
                    </span>
                  </div>
                ))}
              </div>
            </div>

            <div className="bg-gray-50 rounded-lg p-6">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Listing Optimization</h3>
              <div className="space-y-4">
                <div className="bg-white p-4 rounded-md shadow-sm">
                  <h4 className="font-medium text-gray-900">Optimized Title</h4>
                  <p className="mt-2 text-gray-600">{analysis.optimization.title}</p>
                </div>
                <div className="bg-white p-4 rounded-md shadow-sm">
                  <h4 className="font-medium text-gray-900">Bullet Points</h4>
                  <ul className="mt-2 space-y-2">
                    {analysis.optimization.bullets.map((bullet, index) => (
                      <li key={index} className="text-gray-600">• {bullet}</li>
                    ))}
                  </ul>
                </div>
                <div className="bg-white p-4 rounded-md shadow-sm">
                  <h4 className="font-medium text-gray-900">Description</h4>
                  <p className="mt-2 text-gray-600">{analysis.optimization.description}</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}