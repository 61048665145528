import React from 'react';
import { CalendarCheck } from 'lucide-react';

export default function TopBanner() {
  return (
    <div className="bg-brand-primary">
      <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between flex-wrap">
          <div className="w-0 flex-1 flex items-center">
            <span className="flex p-2">
              <CalendarCheck className="h-6 w-6 text-white" aria-hidden="true" />
            </span>
            <p className="ml-3 font-medium text-white truncate">
              <span className="md:hidden">Book your free strategy session!</span>
              <span className="hidden md:inline">Want to improve your SEO? Book your free strategy session with Julian Goldie!</span>
            </p>
          </div>
          <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
            <a
              href="https://go.juliangoldie.com/strategy-session"
              className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-brand-primary bg-white hover:bg-gray-50"
              target="_blank"
              rel="noopener noreferrer"
            >
              Book Now
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}