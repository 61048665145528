import React from 'react';
import KeywordConcatenationTool from '../components/KeywordConcatenationTool';
import CTABanner from '../components/CTABanner';

export function KeywordConcatenationPage() {
  return (
    <div className="py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h1 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Keyword Concatenation Tool
          </h1>
          <p className="mt-4 text-xl text-gray-500 max-w-3xl mx-auto">
            Generate powerful keyword combinations by combining prefixes, main keywords, and suffixes.
          </p>
        </div>
        
        <KeywordConcatenationTool />
        
        <div className="prose prose-lg max-w-3xl mx-auto mt-16">
          <h2>How to Use the Keyword Concatenation Tool</h2>
          <p>
            The keyword concatenation tool helps you generate comprehensive keyword combinations by combining different parts of keywords. This powerful approach allows you to discover new keyword opportunities and expand your SEO strategy.
          </p>

          <h3>Understanding Keyword Concatenation</h3>
          <p>
            Keyword concatenation involves combining different parts of keywords to create new, relevant variations. The tool works with three main components:
          </p>

          <ul>
            <li><strong>Prefixes:</strong> Words that come before your main keyword (e.g., best, top, affordable)</li>
            <li><strong>Main Keywords:</strong> Your primary target keywords or phrases</li>
            <li><strong>Suffixes:</strong> Words that come after your main keyword (e.g., online, service, tool)</li>
          </ul>

          <h3>Getting Started with Keyword Concatenation</h3>
          <p>
            Follow these steps to generate effective keyword combinations:
          </p>

          <ol>
            <li>Enter your prefixes (optional) - one per line</li>
            <li>Input your main keywords (required) - one per line</li>
            <li>Add your suffixes (optional) - one per line</li>
            <li>Click "Generate Combinations" to create variations</li>
            <li>Review and export the results as needed</li>
          </ol>

          <h3>Best Practices for Keyword Concatenation</h3>
          <p>
            To get the most value from your keyword combinations:
          </p>

          <ul>
            <li>Use relevant modifiers as prefixes and suffixes</li>
            <li>Keep combinations natural and readable</li>
            <li>Consider search intent for each combination</li>
            <li>Focus on commercially valuable modifiers</li>
            <li>Test different combination patterns</li>
          </ul>

          <h3>Advanced Concatenation Strategies</h3>
          <p>
            Enhance your keyword research with these advanced techniques:
          </p>

          <ul>
            <li>Combine industry-specific terms with generic modifiers</li>
            <li>Use location-based prefixes or suffixes</li>
            <li>Include action words and buying intent modifiers</li>
            <li>Test different word orders</li>
            <li>Analyze competition for different combinations</li>
          </ul>

          <h3>Implementing Concatenated Keywords</h3>
          <p>
            After generating your keyword combinations:
          </p>

          <ul>
            <li>Prioritize the most relevant combinations</li>
            <li>Check search volume and competition</li>
            <li>Group similar combinations</li>
            <li>Create content strategies around themes</li>
            <li>Monitor performance of different variations</li>
          </ul>
        </div>
      </div>
      <div className="mt-16">
        <CTABanner />
      </div>
    </div>
  );
}