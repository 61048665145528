import React from 'react';
import SEOProjectManagementTool from '../components/SEOProjectManagementTool';
import CTABanner from '../components/CTABanner';

export function SEOProjectManagementPage() {
  return (
    <div className="py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h1 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            SEO Project Management Tool
          </h1>
          <p className="mt-4 text-xl text-gray-500 max-w-3xl mx-auto">
            Streamline your SEO workflows and manage projects efficiently with our comprehensive project management tool.
          </p>
        </div>
        
        <SEOProjectManagementTool />
        
        <div className="prose prose-lg max-w-3xl mx-auto mt-16">
          <h2>How to Use the SEO Project Management Tool</h2>
          <p>
            Our SEO project management tool helps you organize, track, and optimize your SEO campaigns effectively. Whether you're managing multiple clients or handling in-house SEO projects, this tool streamlines your workflow and improves team collaboration.
          </p>

          <h3>Getting Started with SEO Project Management</h3>
          <p>
            The tool provides a comprehensive project management solution specifically designed for SEO tasks:
          </p>
          
          <ul>
            <li>Create and assign SEO tasks to team members</li>
            <li>Set priorities and deadlines for each task</li>
            <li>Track progress through different stages</li>
            <li>Monitor team performance and project status</li>
            <li>Organize tasks using a Kanban-style board</li>
          </ul>

          <h3>Managing SEO Tasks Effectively</h3>
          <p>
            Follow these steps to manage your SEO projects efficiently:
          </p>

          <ol>
            <li>Create detailed tasks with clear objectives</li>
            <li>Assign team members and set deadlines</li>
            <li>Prioritize tasks based on importance</li>
            <li>Track progress through different stages</li>
            <li>Monitor completion and results</li>
          </ol>

          <h3>Task Organization and Workflow</h3>
          <p>
            The tool uses a three-column workflow system:
          </p>

          <ul>
            <li><strong>To Do:</strong> New tasks awaiting action</li>
            <li><strong>In Progress:</strong> Tasks currently being worked on</li>
            <li><strong>Completed:</strong> Successfully finished tasks</li>
          </ul>

          <h3>Advanced Project Management Features</h3>
          <p>
            Leverage these advanced features for better project management:
          </p>

          <ul>
            <li>Priority-based task organization</li>
            <li>Team member assignment and tracking</li>
            <li>Deadline management and reminders</li>
            <li>Progress tracking and reporting</li>
            <li>Task filtering and organization</li>
          </ul>

          <h3>Best Practices for SEO Project Management</h3>
          <p>
            Follow these guidelines for optimal results:
          </p>

          <ul>
            <li>Break down large SEO projects into manageable tasks</li>
            <li>Set realistic deadlines for each task</li>
            <li>Assign clear responsibilities to team members</li>
            <li>Regular progress monitoring and updates</li>
            <li>Maintain clear communication channels</li>
          </ul>
        </div>
      </div>
      <div className="mt-16">
        <CTABanner />
      </div>
    </div>
  );
}