import React, { useState } from 'react';
import { Building, Search, BarChart2, Download, MapPin } from 'lucide-react';

interface HotelSEOAnalysis {
  domain: string;
  metrics: {
    localVisibility: number;
    bookingIntent: number;
    competitorRank: number;
    localPackRanking: number;
  };
  opportunities: Array<{
    type: string;
    impact: 'high' | 'medium' | 'low';
    description: string;
  }>;
  localKeywords: Array<{
    keyword: string;
    volume: number;
    difficulty: number;
    intent: number;
  }>;
}

export default function HotelSEOTool() {
  const [domain, setDomain] = useState('');
  const [location, setLocation] = useState('');
  const [analysis, setAnalysis] = useState<HotelSEOAnalysis | null>(null);

  const analyzeHotel = () => {
    // Simulated hotel SEO analysis
    setAnalysis({
      domain,
      metrics: {
        localVisibility: Math.floor(Math.random() * 30) + 70,
        bookingIntent: Math.floor(Math.random() * 20) + 80,
        competitorRank: Math.floor(Math.random() * 10) + 1,
        localPackRanking: Math.floor(Math.random() * 5) + 1,
      },
      opportunities: [
        {
          type: 'Local SEO',
          impact: 'high',
          description: 'Optimize Google Business Profile with high-quality hotel photos and amenities',
        },
        {
          type: 'Content',
          impact: 'high',
          description: 'Create location-specific landing pages for nearby attractions',
        },
        {
          type: 'Technical',
          impact: 'medium',
          description: 'Implement schema markup for hotel rooms and rates',
        },
      ],
      localKeywords: [
        {
          keyword: `hotels in ${location}`,
          volume: Math.floor(Math.random() * 5000) + 1000,
          difficulty: Math.random() * 0.6,
          intent: Math.random() * 0.3 + 0.7,
        },
        {
          keyword: `${location} luxury hotel`,
          volume: Math.floor(Math.random() * 3000) + 500,
          difficulty: Math.random() * 0.5,
          intent: Math.random() * 0.2 + 0.7,
        },
        {
          keyword: `best hotels near ${location}`,
          volume: Math.floor(Math.random() * 2000) + 300,
          difficulty: Math.random() * 0.4,
          intent: Math.random() * 0.2 + 0.7,
        },
      ],
    });
  };

  const downloadReport = () => {
    if (!analysis) return;

    const reportContent = `
Hotel SEO Analysis Report
Domain: ${analysis.domain}
Location: ${location}

Key Metrics:
- Local Visibility Score: ${analysis.metrics.localVisibility}%
- Booking Intent Score: ${analysis.metrics.bookingIntent}%
- Competitor Rank: ${analysis.metrics.competitorRank}
- Local Pack Ranking: ${analysis.metrics.localPackRanking}

Opportunities:
${analysis.opportunities.map(opp => `
${opp.type} (${opp.impact} impact)
- ${opp.description}
`).join('\n')}

Local Keywords:
${analysis.localKeywords.map(k => `
${k.keyword}
- Monthly Volume: ${k.volume}
- Difficulty: ${(k.difficulty * 100).toFixed()}%
- Booking Intent: ${(k.intent * 100).toFixed()}%
`).join('\n')}
    `.trim();

    const blob = new Blob([reportContent], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${domain}-hotel-seo-report.txt`;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6 max-w-4xl mx-auto">
      <div className="flex items-center mb-6">
        <Building className="h-8 w-8 text-brand-primary mr-3" />
        <h2 className="text-2xl font-bold text-gray-900">Hotel SEO Tool</h2>
      </div>

      <div className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label htmlFor="domain" className="block text-sm font-medium text-gray-700">
              Hotel Website
            </label>
            <input
              type="text"
              id="domain"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-primary focus:ring-brand-primary sm:text-sm"
              value={domain}
              onChange={(e) => setDomain(e.target.value)}
              placeholder="Enter your hotel domain"
            />
          </div>
          <div>
            <label htmlFor="location" className="block text-sm font-medium text-gray-700">
              Hotel Location
            </label>
            <input
              type="text"
              id="location"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-primary focus:ring-brand-primary sm:text-sm"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              placeholder="Enter your hotel location"
            />
          </div>
        </div>

        <div className="flex gap-4">
          <button
            onClick={analyzeHotel}
            className="btn-primary flex-grow flex items-center justify-center"
          >
            <Search className="h-5 w-5 mr-2" />
            Analyze Hotel SEO
          </button>
          {analysis && (
            <button
              onClick={downloadReport}
              className="btn-secondary flex items-center"
            >
              <Download className="h-5 w-5 mr-2" />
              Export Report
            </button>
          )}
        </div>

        {analysis && (
          <div className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              <div className="bg-gray-50 rounded-lg p-4">
                <h3 className="text-sm font-medium text-gray-500">Local Visibility</h3>
                <p className="mt-2 text-3xl font-bold text-gray-900">
                  {analysis.metrics.localVisibility}%
                </p>
              </div>
              <div className="bg-gray-50 rounded-lg p-4">
                <h3 className="text-sm font-medium text-gray-500">Booking Intent</h3>
                <p className="mt-2 text-3xl font-bold text-gray-900">
                  {analysis.metrics.bookingIntent}%
                </p>
              </div>
              <div className="bg-gray-50 rounded-lg p-4">
                <h3 className="text-sm font-medium text-gray-500">Competitor Rank</h3>
                <p className="mt-2 text-3xl font-bold text-gray-900">
                  #{analysis.metrics.competitorRank}
                </p>
              </div>
              <div className="bg-gray-50 rounded-lg p-4">
                <h3 className="text-sm font-medium text-gray-500">Local Pack</h3>
                <p className="mt-2 text-3xl font-bold text-gray-900">
                  #{analysis.metrics.localPackRanking}
                </p>
              </div>
            </div>

            <div className="bg-gray-50 rounded-lg p-6">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Growth Opportunities</h3>
              <div className="space-y-4">
                {analysis.opportunities.map((opp, index) => (
                  <div key={index} className="bg-white p-4 rounded-md shadow-sm">
                    <div className="flex items-center justify-between">
                      <h4 className="font-medium text-gray-900">{opp.type}</h4>
                      <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                        opp.impact === 'high' ? 'bg-red-100 text-red-800' :
                        opp.impact === 'medium' ? 'bg-yellow-100 text-yellow-800' :
                        'bg-green-100 text-green-800'
                      }`}>
                        {opp.impact.toUpperCase()} Impact
                      </span>
                    </div>
                    <p className="mt-2 text-sm text-gray-600">{opp.description}</p>
                  </div>
                ))}
              </div>
            </div>

            <div className="bg-gray-50 rounded-lg p-6">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Local Keywords</h3>
              <div className="space-y-4">
                {analysis.localKeywords.map((keyword, index) => (
                  <div key={index} className="bg-white p-4 rounded-md shadow-sm">
                    <div className="flex items-center justify-between">
                      <h4 className="font-medium text-gray-900">{keyword.keyword}</h4>
                      <span className="text-sm text-gray-500">
                        {keyword.volume.toLocaleString()} searches/mo
                      </span>
                    </div>
                    <div className="mt-2 grid grid-cols-2 gap-4 text-sm text-gray-600">
                      <div>
                        Difficulty: {(keyword.difficulty * 100).toFixed()}%
                      </div>
                      <div>
                        Intent: {(keyword.intent * 100).toFixed()}%
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}