import React from 'react';
import EcommerceSEOTool from '../components/EcommerceSEOTool';
import CTABanner from '../components/CTABanner';

export function EcommerceSEOPage() {
  return (
    <div className="py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h1 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Best SEO Tool for Ecommerce
          </h1>
          <p className="mt-4 text-xl text-gray-500 max-w-3xl mx-auto">
            Optimize your online store's search visibility and boost sales with our specialized ecommerce SEO tool.
          </p>
        </div>
        
        <EcommerceSEOTool />
        
        <div className="prose prose-lg max-w-3xl mx-auto mt-16">
          <h2>How to Use the Best SEO Tool for Ecommerce</h2>
          <p>
            Our specialized ecommerce SEO tool helps online retailers improve their search visibility and drive more sales. Whether you're running a small boutique or a large online store, this comprehensive guide will show you how to leverage our tool for maximum impact.
          </p>

          <h3>Understanding Ecommerce SEO Metrics</h3>
          <p>
            The tool provides key metrics specific to online retail:
          </p>
          
          <ul>
            <li><strong>Organic Traffic:</strong> Monthly visitors from search engines</li>
            <li><strong>Conversion Rate:</strong> Percentage of visitors making purchases</li>
            <li><strong>Revenue Impact:</strong> Potential revenue from SEO improvements</li>
            <li><strong>Product Visibility:</strong> How well your products appear in search</li>
          </ul>

          <h3>Optimizing Your Online Store</h3>
          <p>
            Follow these steps to improve your store's search visibility:
          </p>

          <ol>
            <li>Enter your store's domain</li>
            <li>Select your ecommerce platform</li>
            <li>Analyze store metrics</li>
            <li>Review optimization opportunities</li>
            <li>Implement recommended changes</li>
          </ol>

          <h3>Product Page Optimization</h3>
          <p>
            Focus on these key areas for product success:
          </p>

          <ul>
            <li>Keyword-rich product titles</li>
            <li>Detailed product descriptions</li>
            <li>High-quality product images</li>
            <li>Customer reviews integration</li>
            <li>Schema markup implementation</li>
          </ul>

          <h3>Category Page Strategies</h3>
          <p>
            Enhance your category pages with:
          </p>

          <ul>
            <li>Optimized category structures</li>
            <li>Internal linking strategies</li>
            <li>Category descriptions</li>
            <li>Filtered navigation</li>
            <li>Related products</li>
          </ul>

          <h3>Technical SEO for Ecommerce</h3>
          <p>
            Ensure your technical foundation is solid:
          </p>

          <ul>
            <li>Mobile optimization</li>
            <li>Site speed optimization</li>
            <li>URL structure</li>
            <li>XML sitemaps</li>
            <li>Canonical tags</li>
          </ul>
        </div>
      </div>
      <div className="mt-16">
        <CTABanner />
      </div>
    </div>
  );
}