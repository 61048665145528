import React, { useState } from 'react';
import { FileText, BarChart2, Download, Calendar, RefreshCw } from 'lucide-react';

interface AutomatedReport {
  metrics: {
    organicTraffic: number;
    rankings: number;
    backlinks: number;
    conversions: number;
  };
  trends: {
    period: string;
    traffic: number;
    growth: number;
  }[];
  insights: {
    type: string;
    message: string;
    impact: 'positive' | 'negative' | 'neutral';
  }[];
}

export default function AutomatedSEOReportingTool() {
  const [domain, setDomain] = useState('');
  const [frequency, setFrequency] = useState('weekly');
  const [email, setEmail] = useState('');
  const [report, setReport] = useState<AutomatedReport | null>(null);

  const generateReport = () => {
    // Simulated automated report generation
    setReport({
      metrics: {
        organicTraffic: Math.floor(Math.random() * 100000) + 50000,
        rankings: Math.floor(Math.random() * 1000) + 500,
        backlinks: Math.floor(Math.random() * 5000) + 1000,
        conversions: Math.floor(Math.random() * 1000) + 100,
      },
      trends: [
        { period: 'Week 1', traffic: 52000, growth: 5.2 },
        { period: 'Week 2', traffic: 54600, growth: 4.8 },
        { period: 'Week 3', traffic: 57100, growth: 4.5 },
        { period: 'Week 4', traffic: 60000, growth: 5.0 },
      ],
      insights: [
        {
          type: 'Traffic',
          message: 'Organic traffic increased by 15% this month',
          impact: 'positive',
        },
        {
          type: 'Keywords',
          message: '10 new keywords entered top 10 positions',
          impact: 'positive',
        },
        {
          type: 'Technical',
          message: 'Page load speed needs improvement on mobile',
          impact: 'negative',
        },
      ],
    });
  };

  const setupAutomation = () => {
    alert(`Automated reports will be sent to ${email} ${frequency}`);
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6 max-w-4xl mx-auto">
      <div className="flex items-center mb-6">
        <RefreshCw className="h-8 w-8 text-brand-primary mr-3" />
        <h2 className="text-2xl font-bold text-gray-900">Automated SEO Reporting Tool</h2>
      </div>

      <div className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <input
            type="text"
            placeholder="Enter your domain"
            className="rounded-md border-gray-300 shadow-sm focus:border-brand-primary focus:ring-brand-primary"
            value={domain}
            onChange={(e) => setDomain(e.target.value)}
          />
          <select
            className="rounded-md border-gray-300 shadow-sm focus:border-brand-primary focus:ring-brand-primary"
            value={frequency}
            onChange={(e) => setFrequency(e.target.value)}
          >
            <option value="daily">Daily Reports</option>
            <option value="weekly">Weekly Reports</option>
            <option value="monthly">Monthly Reports</option>
          </select>
          <input
            type="email"
            placeholder="Email for reports"
            className="rounded-md border-gray-300 shadow-sm focus:border-brand-primary focus:ring-brand-primary"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div className="flex gap-4">
          <button
            onClick={generateReport}
            className="btn-primary flex-grow flex items-center justify-center"
          >
            <FileText className="h-5 w-5 mr-2" />
            Generate Sample Report
          </button>
          <button
            onClick={setupAutomation}
            className="btn-secondary flex items-center"
          >
            <Calendar className="h-5 w-5 mr-2" />
            Setup Automation
          </button>
        </div>

        {report && (
          <div className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              <div className="bg-gray-50 rounded-lg p-4">
                <h3 className="text-sm font-medium text-gray-500">Organic Traffic</h3>
                <p className="mt-2 text-3xl font-bold text-gray-900">
                  {report.metrics.organicTraffic.toLocaleString()}
                </p>
              </div>
              <div className="bg-gray-50 rounded-lg p-4">
                <h3 className="text-sm font-medium text-gray-500">Rankings</h3>
                <p className="mt-2 text-3xl font-bold text-gray-900">
                  {report.metrics.rankings.toLocaleString()}
                </p>
              </div>
              <div className="bg-gray-50 rounded-lg p-4">
                <h3 className="text-sm font-medium text-gray-500">Backlinks</h3>
                <p className="mt-2 text-3xl font-bold text-gray-900">
                  {report.metrics.backlinks.toLocaleString()}
                </p>
              </div>
              <div className="bg-gray-50 rounded-lg p-4">
                <h3 className="text-sm font-medium text-gray-500">Conversions</h3>
                <p className="mt-2 text-3xl font-bold text-gray-900">
                  {report.metrics.conversions.toLocaleString()}
                </p>
              </div>
            </div>

            <div className="bg-gray-50 rounded-lg p-6">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Traffic Trends</h3>
              <div className="space-y-4">
                {report.trends.map((trend, index) => (
                  <div key={index} className="flex items-center justify-between">
                    <span className="text-gray-600">{trend.period}</span>
                    <div className="flex items-center space-x-4">
                      <span className="text-gray-900">{trend.traffic.toLocaleString()}</span>
                      <span className={`flex items-center ${trend.growth >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                        {trend.growth >= 0 ? '+' : ''}{trend.growth}%
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="bg-gray-50 rounded-lg p-6">
              <h3 className="text-lg font-medium text-gray-900 mb-4">AI-Powered Insights</h3>
              <div className="space-y-4">
                {report.insights.map((insight, index) => (
                  <div key={index} className="flex items-start space-x-3">
                    <span className={`flex-shrink-0 text-2xl ${
                      insight.impact === 'positive' ? 'text-green-500' :
                      insight.impact === 'negative' ? 'text-red-500' :
                      'text-yellow-500'
                    }`}>
                      {insight.impact === 'positive' ? '↑' :
                       insight.impact === 'negative' ? '↓' : '→'}
                    </span>
                    <div>
                      <p className="font-medium text-gray-900">{insight.type}</p>
                      <p className="text-gray-600">{insight.message}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}