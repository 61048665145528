import React, { useState } from 'react';
import { FileText, Search, Brain, Download } from 'lucide-react';

interface SemanticAnalysis {
  mainTopic: string;
  semanticScore: number;
  relatedTopics: Array<{
    topic: string;
    relevance: number;
  }>;
  suggestions: Array<{
    type: string;
    suggestion: string;
  }>;
}

export default function SemanticSEOTool() {
  const [content, setContent] = useState('');
  const [targetKeyword, setTargetKeyword] = useState('');
  const [analysis, setAnalysis] = useState<SemanticAnalysis | null>(null);

  const analyzeContent = () => {
    // Simulated semantic analysis
    setAnalysis({
      mainTopic: targetKeyword,
      semanticScore: Math.floor(Math.random() * 30) + 70,
      relatedTopics: [
        { topic: `${targetKeyword} best practices`, relevance: 0.95 },
        { topic: `${targetKeyword} examples`, relevance: 0.88 },
        { topic: `how to use ${targetKeyword}`, relevance: 0.85 },
        { topic: `${targetKeyword} benefits`, relevance: 0.82 },
        { topic: `${targetKeyword} tools`, relevance: 0.78 },
      ],
      suggestions: [
        { type: 'Entity', suggestion: 'Add more industry-specific terminology' },
        { type: 'Context', suggestion: 'Expand on use cases and applications' },
        { type: 'Structure', suggestion: 'Include more semantic HTML markup' },
        { type: 'Topics', suggestion: 'Cover related concepts more thoroughly' },
      ],
    });
  };

  const downloadReport = () => {
    if (!analysis) return;

    const reportContent = `
Semantic SEO Analysis Report

Main Topic: ${analysis.mainTopic}
Semantic Score: ${analysis.semanticScore}/100

Related Topics:
${analysis.relatedTopics.map(topic => `- ${topic.topic} (Relevance: ${(topic.relevance * 100).toFixed()}%)`).join('\n')}

Suggestions:
${analysis.suggestions.map(sugg => `${sugg.type}: ${sugg.suggestion}`).join('\n')}
    `.trim();

    const blob = new Blob([reportContent], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'semantic-seo-analysis.txt';
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6 max-w-4xl mx-auto">
      <div className="flex items-center mb-6">
        <Brain className="h-8 w-8 text-brand-primary mr-3" />
        <h2 className="text-2xl font-bold text-gray-900">Semantic SEO Tool</h2>
      </div>

      <div className="space-y-6">
        <div>
          <label htmlFor="targetKeyword" className="block text-sm font-medium text-gray-700">
            Target Keyword
          </label>
          <input
            type="text"
            id="targetKeyword"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-primary focus:ring-brand-primary sm:text-sm"
            value={targetKeyword}
            onChange={(e) => setTargetKeyword(e.target.value)}
            placeholder="Enter your main topic or keyword"
          />
        </div>

        <div>
          <label htmlFor="content" className="block text-sm font-medium text-gray-700">
            Content to Analyze
          </label>
          <textarea
            id="content"
            rows={8}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-primary focus:ring-brand-primary sm:text-sm"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            placeholder="Paste your content here for semantic analysis..."
          />
        </div>

        <div className="flex gap-4">
          <button
            onClick={analyzeContent}
            className="btn-primary flex-grow flex items-center justify-center"
          >
            <Search className="h-5 w-5 mr-2" />
            Analyze Content
          </button>
          {analysis && (
            <button
              onClick={downloadReport}
              className="btn-secondary flex items-center"
            >
              <Download className="h-5 w-5 mr-2" />
              Export Report
            </button>
          )}
        </div>

        {analysis && (
          <div className="space-y-6">
            <div className="bg-gray-50 rounded-lg p-6">
              <div className="flex items-center justify-between">
                <h3 className="text-lg font-medium text-gray-900">Semantic Score</h3>
                <span className="text-3xl font-bold text-brand-primary">
                  {analysis.semanticScore}/100
                </span>
              </div>
            </div>

            <div className="bg-gray-50 rounded-lg p-6">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Related Topics</h3>
              <div className="space-y-3">
                {analysis.relatedTopics.map((topic, index) => (
                  <div key={index} className="flex items-center justify-between">
                    <span className="text-gray-700">{topic.topic}</span>
                    <span className="text-sm text-gray-500">
                      {(topic.relevance * 100).toFixed()}% relevance
                    </span>
                  </div>
                ))}
              </div>
            </div>

            <div className="bg-gray-50 rounded-lg p-6">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Optimization Suggestions</h3>
              <div className="space-y-4">
                {analysis.suggestions.map((suggestion, index) => (
                  <div key={index} className="flex items-start">
                    <span className="text-brand-primary font-medium min-w-[80px]">
                      {suggestion.type}:
                    </span>
                    <span className="text-gray-700 ml-2">{suggestion.suggestion}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}