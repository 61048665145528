import React, { useState } from 'react';
import { Building, Search, BarChart2, Download, CheckCircle } from 'lucide-react';

interface SEOAudit {
  score: number;
  recommendations: {
    category: string;
    items: Array<{
      title: string;
      priority: 'high' | 'medium' | 'low';
      status: 'good' | 'needs-work';
    }>;
  }[];
}

export default function SmallBusinessSEOTool() {
  const [url, setUrl] = useState('');
  const [audit, setAudit] = useState<SEOAudit | null>(null);

  const runAudit = () => {
    // Simulated audit for demonstration
    setAudit({
      score: 78,
      recommendations: [
        {
          category: 'Technical SEO',
          items: [
            { title: 'Mobile Responsiveness', priority: 'high', status: 'good' },
            { title: 'Page Load Speed', priority: 'high', status: 'needs-work' },
            { title: 'SSL Certificate', priority: 'high', status: 'good' },
          ],
        },
        {
          category: 'On-Page SEO',
          items: [
            { title: 'Meta Descriptions', priority: 'medium', status: 'needs-work' },
            { title: 'Header Tags', priority: 'medium', status: 'good' },
            { title: 'Image Alt Text', priority: 'low', status: 'needs-work' },
          ],
        },
        {
          category: 'Local SEO',
          items: [
            { title: 'Google Business Profile', priority: 'high', status: 'needs-work' },
            { title: 'Local Citations', priority: 'medium', status: 'needs-work' },
            { title: 'Customer Reviews', priority: 'high', status: 'good' },
          ],
        },
      ],
    });
  };

  const getPriorityColor = (priority: string) => {
    switch (priority) {
      case 'high': return 'text-red-500';
      case 'medium': return 'text-yellow-500';
      case 'low': return 'text-green-500';
      default: return 'text-gray-500';
    }
  };

  const downloadReport = () => {
    if (!audit) return;

    const reportContent = `
Small Business SEO Audit Report
Website: ${url}
Overall Score: ${audit.score}/100

${audit.recommendations.map(category => `
${category.category}:
${category.items.map(item => `- ${item.title}
  Priority: ${item.priority}
  Status: ${item.status}`).join('\n')}`).join('\n\n')}
    `.trim();

    const blob = new Blob([reportContent], { type: 'text/plain' });
    const downloadUrl = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.download = 'small-business-seo-audit.txt';
    a.click();
    window.URL.revokeObjectURL(downloadUrl);
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6 max-w-4xl mx-auto">
      <div className="flex items-center mb-6">
        <Building className="h-8 w-8 text-brand-primary mr-3" />
        <h2 className="text-2xl font-bold text-gray-900">Best SEO Tool for Small Business</h2>
      </div>

      <div className="space-y-6">
        <div>
          <label htmlFor="url" className="block text-sm font-medium text-gray-700">
            Your Website URL
          </label>
          <div className="mt-1 flex rounded-md shadow-sm">
            <input
              type="text"
              id="url"
              className="flex-1 block w-full rounded-md border-gray-300 focus:border-brand-primary focus:ring-brand-primary sm:text-sm"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              placeholder="Enter your website URL (e.g., www.yourbusiness.com)"
            />
            <button
              onClick={runAudit}
              className="ml-3 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-brand-primary hover:bg-brand-darker focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-primary"
            >
              <Search className="h-4 w-4 mr-2" />
              Run Audit
            </button>
          </div>
        </div>

        {audit && (
          <div className="space-y-6">
            <div className="bg-gray-50 rounded-lg p-6 text-center">
              <h3 className="text-lg font-medium text-gray-900 mb-2">Overall SEO Score</h3>
              <div className="text-4xl font-bold text-brand-primary">{audit.score}/100</div>
            </div>

            {audit.recommendations.map((category, index) => (
              <div key={index} className="bg-gray-50 rounded-lg p-6">
                <h3 className="text-lg font-medium text-gray-900 mb-4">{category.category}</h3>
                <div className="space-y-4">
                  {category.items.map((item, itemIndex) => (
                    <div key={itemIndex} className="flex items-center justify-between bg-white p-4 rounded-md shadow-sm">
                      <div className="flex items-center">
                        <CheckCircle className={`h-5 w-5 mr-2 ${item.status === 'good' ? 'text-green-500' : 'text-red-500'}`} />
                        <span className="font-medium text-gray-900">{item.title}</span>
                      </div>
                      <div className="flex items-center space-x-4">
                        <span className={getPriorityColor(item.priority)}>
                          {item.priority.charAt(0).toUpperCase() + item.priority.slice(1)} Priority
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}

            <button
              onClick={downloadReport}
              className="btn-secondary w-full flex items-center justify-center"
            >
              <Download className="h-5 w-5 mr-2" />
              Download Full Report
            </button>
          </div>
        )}
      </div>
    </div>
  );
}