import React, { useState } from 'react';
import { Search, TrendingUp, Image, Hash } from 'lucide-react';

interface PinterestKeyword {
  keyword: string;
  monthlySearches: number;
  pinCount: number;
  competition: number;
  trend: 'up' | 'down' | 'stable';
  suggestedHashtags: string[];
}

export default function PinterestKeywordTool() {
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState<PinterestKeyword[]>([]);

  const analyzeKeywords = () => {
    // Simulated keyword analysis
    const demoResults: PinterestKeyword[] = [
      {
        keyword: `${searchTerm} ideas`,
        monthlySearches: 15200,
        pinCount: 45000,
        competition: 0.4,
        trend: 'up',
        suggestedHashtags: [`#${searchTerm}`, '#pinterestideas', '#creative'],
      },
      {
        keyword: `${searchTerm} inspiration`,
        monthlySearches: 12800,
        pinCount: 38000,
        competition: 0.5,
        trend: 'up',
        suggestedHashtags: [`#${searchTerm}inspiration`, '#pinterestinspiration'],
      },
      {
        keyword: `diy ${searchTerm}`,
        monthlySearches: 9500,
        pinCount: 25000,
        competition: 0.3,
        trend: 'stable',
        suggestedHashtags: [`#diy${searchTerm}`, '#diycrafts', '#pinterestdiy'],
      },
    ];

    setResults(demoResults);
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6 max-w-4xl mx-auto">
      <div className="flex items-center mb-6">
        <Image className="h-8 w-8 text-brand-primary mr-3" />
        <h2 className="text-2xl font-bold text-gray-900">Free Pinterest Keyword Tool</h2>
      </div>

      <div className="space-y-6">
        <div>
          <label htmlFor="searchTerm" className="block text-sm font-medium text-gray-700">
            Enter Your Pinterest Topic
          </label>
          <div className="mt-1 flex rounded-md shadow-sm">
            <input
              type="text"
              id="searchTerm"
              className="flex-1 block w-full rounded-md border-gray-300 focus:border-brand-primary focus:ring-brand-primary sm:text-sm"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="e.g., home decor, recipes, crafts"
            />
            <button
              onClick={analyzeKeywords}
              className="ml-3 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-brand-primary hover:bg-brand-darker focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-primary"
            >
              <Search className="h-4 w-4 mr-2" />
              Find Keywords
            </button>
          </div>
        </div>

        {results.length > 0 && (
          <div className="mt-8">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Pinterest Keyword Opportunities</h3>
            <div className="space-y-4">
              {results.map((result, index) => (
                <div key={index} className="bg-gray-50 rounded-lg p-4">
                  <div className="flex justify-between items-start">
                    <div>
                      <h4 className="text-lg font-medium text-brand-primary">{result.keyword}</h4>
                      <div className="mt-2 grid grid-cols-2 gap-4 text-sm text-gray-600">
                        <div>
                          <span className="font-medium">Monthly Searches:</span>{' '}
                          {result.monthlySearches.toLocaleString()}
                        </div>
                        <div>
                          <span className="font-medium">Total Pins:</span>{' '}
                          {result.pinCount.toLocaleString()}
                        </div>
                        <div>
                          <span className="font-medium">Competition:</span>{' '}
                          {(result.competition * 100).toFixed()}%
                        </div>
                        <div className="flex items-center">
                          <span className="font-medium mr-1">Trend:</span>
                          {result.trend === 'up' && <TrendingUp className="h-4 w-4 text-green-500" />}
                          {result.trend === 'down' && (
                            <TrendingUp className="h-4 w-4 text-red-500 transform rotate-180" />
                          )}
                          {result.trend === 'stable' && <Hash className="h-4 w-4 text-yellow-500" />}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3">
                    <h5 className="text-sm font-medium text-gray-700">Suggested Hashtags:</h5>
                    <div className="mt-1 flex flex-wrap gap-2">
                      {result.suggestedHashtags.map((hashtag, idx) => (
                        <span
                          key={idx}
                          className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-brand-primary bg-opacity-10 text-brand-primary"
                        >
                          {hashtag}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}