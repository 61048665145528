import React from 'react';
import { Helmet } from 'react-helmet-async';
import KGRCalculator from '../components/KGRCalculator';
import CTABanner from '../components/CTABanner';

export function KGRCalculatorPage() {
  return (
    <>
      <Helmet>
        <title>Free Keyword Golden Ratio (KGR) Calculator | Find Low Competition Keywords</title>
        <meta name="description" content="Calculate your Keyword Golden Ratio (KGR) with our free tool. Find low-competition keywords that you can easily rank for. Boost your SEO strategy with KGR analysis." />
        <link rel="canonical" href="https://bestfreeseocalculators.com/kgr-calculator" />
      </Helmet>
      <div className="py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <h1 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
              Keyword Golden Ratio Calculator
            </h1>
            <p className="mt-4 text-xl text-gray-500 max-w-3xl mx-auto">
              Calculate the Keyword Golden Ratio (KGR) to find low-competition keywords that you can easily rank for.
            </p>
          </div>
          <KGRCalculator />
          <div className="prose prose-lg max-w-3xl mx-auto mt-16">
            <h2>How to Use the Keyword Golden Ratio Calculator</h2>
            <p>
              The Keyword Golden Ratio (KGR) is a powerful metric developed by Doug Cunnington to find low-competition keywords. Here's how to use it effectively:
            </p>
            <ol>
              <li>Enter the number of Google "allintitle:" results for your keyword</li>
              <li>Input the monthly search volume for the keyword</li>
              <li>The calculator will determine if it's a good KGR opportunity</li>
            </ol>
            <h2>Understanding KGR Results</h2>
            <ul>
              <li>KGR &lt; 0.25: Excellent opportunity</li>
              <li>KGR between 0.25 and 1: Moderate opportunity</li>
              <li>KGR &gt; 1: High competition</li>
            </ul>
          </div>
        </div>
        <div className="mt-16">
          <CTABanner />
        </div>
      </div>
    </>
  );
}